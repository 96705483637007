import staff from "../Images/Gallery/Annual/staff.jpeg";
import BOD from "../Images/Gallery/Annual/BOD.jpeg";
import preprimary from "../Images/Gallery/Annual/pre-primary.jpeg";
import nursery from "../Images/Gallery/Annual/Nursery.jpeg";
import lkg from "../Images/Gallery/Annual/LKG.jpeg";
import ukg from "../Images/Gallery/Annual/UKG.jpeg";
import one from "../Images/Gallery/Annual/1.jpeg";
import two from "../Images/Gallery/Annual/2.jpeg";
import three from "../Images/Gallery/Annual/3.jpeg";
import four from "../Images/Gallery/Annual/4.jpeg";
import five from "../Images/Gallery/Annual/5.jpeg";
import six from "../Images/Gallery/Annual/6.jpeg";
import seven from "../Images/Gallery/Annual/7.jpeg";
import eight from "../Images/Gallery/Annual/8.jpeg";
import nine from "../Images/Gallery/Annual/9.jpeg";
import ten from "../Images/Gallery/Annual/10.jpeg";
import eleven from "../Images/Gallery/Annual/11.jpeg";
import twelve from "../Images/Gallery/Annual/12.jpeg";

export const galleryImages = [
  {
    id: 1,
    src: staff,
  },
  {
    id: 2,
    src: BOD,
  },
  {
    id: 3,
    src: preprimary,
  },
  {
    id: 4,
    src: nursery,
  },
  {
    id: 5,
    src: lkg,
  },
  {
    id: 6,
    src: ukg,
  },
  {
    id: 7,
    src: one,
  },
  {
    id: 8,
    src: two,
  },
  {
    id: 9,
    src: three,
  },
  {
    id: 10,
    src: four,
  },
  {
    id: 11,
    src: five,
  },
  {
    id: 12,
    src: five,
  },
  {
    id: 13,
    src: six,
  },
  {
    id: 14,
    src: seven,
  },
  {
    id: 15,
    src: eight,
  },
  {
    id: 16,
    src: nine,
  },
  {
    id: 17,
    src: ten,
  },
  {
    id: 18,
    src: eleven,
  },
  {
    id: 19,
    src: twelve,
  },
];
