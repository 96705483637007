import React from "react";

//import SEEBasketBallImg from "../../Images/SEEBasketBall/13.jpg";
import fustal from "../../Images/fustal/trophy.JPG"
import SEETrophy from "../../Images/fustal/45.jpg";
import GroupAdmin from "../../Images/fustal/46.jpg";
import { Link } from "react-router-dom";
import SEEFutsalGallery from "../../Events/Events/SEEFutsalGallery"

const SEECupFutsaltournament = () => {
  const shareUrl =
    "https://www.geetanjalischoolcollege.edu.np/see-basketball-2081-end";
  

  return (
    <div className="container">
      <div className="article">
        <h1 className="article-title">
          SEE Geetanjali Futsal Tournament completed
        </h1>
        <span className="header-image">
          <img src={fustal} alt="" />
        </span>
        <div className="article-content">
          <p>
            Jestha 25th, Kathmandu. The first Geetanjali SEE Cup-2081(One Day Knockout futsal Tournament) of
            Geetanjali School/College located in Khusibun, Town-Planning, Kathmandu has been completed.
            
          </p>
          <p>
            Geetanjali School/College organized a futsal tournament for
            students who had passed SEE. Among the 30 teams participating in the
            One-Day Knockout Futsal competition from all over Nepal, Samriddhi School won
            the first place. Similarly, Heartland Academy  got
            the second place .
          </p>
          <p>
           Samriddhi School's Laxman Thami has received the best player award in
            the tournament along with Rs 1000 cash prize and certificates.Also, Samriddhi School's Bijay Gurung 
            has received the best goalkeeper award in the tournament along with Rs 1000 cash prize and certificates.
            Also, Nagarjun Academy's Roshan Rana Magar has received the highest goal-scorer award in the tournament 
            along with Rs 1000 cash prize and certificates.
             The first team in the competition was awarded with a cash
            prize of Rs. 20000, second team was awarded with a cash prize of Rs.10000 along  with medals and
            certificates.Awards and Cash prize were handed by Bichari Prasad Adhikari (Head of Kathmandu District Education Development and Coordination Unit)
            Bed Prasad Pant (Principal of Geetanjali School/College), Gobinda Prasad Acharya (Chairman of Geetanjali School/College) and Senior Sports journalist Raju Silwal.
          </p>
          <img src={SEETrophy} alt="Geetanjali Futsal Team along with Principal aand Chairman" />
          <p>
            Speaking at the closing ceremony of the basketball tournament,
            Principal Bed Prasad Pant said that the game was organized with the aim of identifying and revealing the hidden talents of the students.
             He said that the game helps to maintain physical and mental fitness along with developing discipline, cooperation and brotherhood.
              Principal Pant said that Futsal tournament was organized with the aim of developing the students through the medium of the game 
              and helping them in nation building and under the social responsibility, to build the jewels of the nation.
          </p>
          <img src={GroupAdmin} alt="Principal Bed Prasad Panta handing the ball to refree" />
          <p>
          At the closing ceremony of the Futsal tournament, the national football/futsal players from Nepal's national clubs were present 
          including Youth National Player Amit Tamang, Futsal National Player Urken Sherpa, Under 19 Player Chandan Das,
           A Division Club (NRT) player Richard Wilson from Ghana, Futsal Player Amit Lama along with Senior Journalist Raju Silwal, 
           Finance Director Bhim Prasad Bhattarai, +2 Coordinator Bashudev Adhikari, Principals, teachers and staff of various schools including school coordinators.
          </p>
          <p>
          Futsal tournament matches were officiated by national referees Maya Lama, Uma Shrestha, Diwas Magar and Pradeep Gurung.
          </p>
          
          
          
          <p>
            Article by:{" "}
            <Link to="https://bidharthi.com/content/2963" target="_blank">
              www.bidharthi.com
            </Link>
          </p>
          <p>
            Click read more button to read more detail about the competition
            <br />
            <Link to="https://bidharthi.com/content/2963" target="_blank">
              <button className="btn btn-sm btn-primary">Read More</button>
            </Link>
          </p>
        </div>
      </div>

      <SEEFutsalGallery />
    </div>
  );
};

export default SEECupFutsaltournament;
