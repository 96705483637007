import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Notice from "../Images/11-result.jpg";


const HomePopup = () => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "fixed", 
    top: "10%", 
    left: "10%", 
    width: "100%", 
    height: "auto", 
    maxHeight: "80%", 
    overflow: "auto", 
    transform: "none", 
    backgroundColor: "white", 
    border: "2px solid #000",
    boxShadow: 24,
    padding: "16px",
    boxSizing: "border-box", 
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="home-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            
          </Typography>
          <img src={Notice} />

      <div className="d-flex justify-content-end gap-2 align-items-center mt-4">
            <Link to="/result">
              <button className="btn btn-sm btn-primary">
                View More
              </button>
            </Link>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default HomePopup;
