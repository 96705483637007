import React from "react";
import Preprimary from "../../Images/classes/pre-primary.webp";
import College from "../../Images/classes/college.webp";
import computerLab from "../../Images/computer-lab.jpg";

const GeetanjaliManagementCollege = () => {
  const shareUrl = "https://www.geetanjalischoolcollege.edu.np/saraswati-puja";

  const ogTitle = "A Glimpse Of Geetanjali";
  const ogDescription =
    "Geetanjali school/college is named after the holy scriptures by Rabindra Nath Tagore- a noble prize winner literary piece “Geetanjali” hoping to tower its name and fame like the same.";
  const ogImageUrl = "../../Images/school.jpeg";
  return (
    <div className="container">
      <div className="article">
        <h1 className="article-title">A Glimpse Of Geetanjali</h1>
        <span className="header-image"></span>
        <div className="article-content">
          <p className="text-justify">
            Geetanjali school/college is named after the holy scriptures by
            Rabindra Nath Tagore- a noble prize winner literary piece
            “Geetanjali” hoping to tower its name and fame like the same. This
            is a co-educational institution where students from play group to
            bachelor level can study with various subjects of their interest and
            choice in the same premises under parental guidence of devoted
            teaching faculties and administrative experts. To attain this
            academic, social, physical and infrastructural height, Geetanjli has
            strived a lot in the respective fields for more than two decades so
            far. The school wing was established in 2043 B.S. (1986 A.D) only
            upto grade five and it was gradually upgraded to Bachelors’ level in
            2065 B.S. The year “2059”B.S. was very remarkable year as the school
            could appear in national level examinations called S.L.C. (School
            Leaving Certificate ) – simply taken an iron gate for further
            studies. The development of the institution did not stop only upto
            SLC rather the management committee started +2 ( i.e. 10+2 ) in 2062
            B.S. and later started bachelor level in 2065 B.S. During this
            period of more than three deacades, the institution has experienced
            ups and downs and has now been quite experienced to understand the
            government policy, to go with parents’ aspiration and understand and
            fulfill students demands and quite enough able to cope with the
            competitors.
          </p>
          <img
            src={computerLab}
            alt="geetanjali pre primary level"
            className="w-100"
            style={{ height: "600px", objectFit: "cover" }}
          />
          <p className="text-justify">
            Now the institution runs from morning 6:00 AM to 5:00 PM in the
            evening on full strength - 6 days a week except on public holidays.
            Bachelor and +2 classes run during morning horus (6:00 AM to 11: 00
            AM) and pre- school and school run during day time (9:30 AM to 5: 00
            PM). Geetanjali has run Business Studies courses- a fundamental
            theoritical course that links to applied business arena in bachelor
            level and in +2 level; various subjects like Accounting, Economics,
            Hotel Management, Computer Science, Travel Tourism, Business
            Studies, Mass Communication, Sociology, Rural Development, Major
            English, Major Nepali, Major Mathematics, Law, Music are extra
            additional courses that adds basic foundation for high school
            students . Studnts around 1000 graduate from this institution every
            year which is a matter of pride for our glorious institution. The
            school/college owns a hygienic cafeteria, spacious play ground,
            volleyball and basketball ground, table tenis station, badminton
            court and an auditorium hall which are its essential extra
            ornamental assets. Besides that we have library, computer lab and
            science laboratoty as a part of regular study and learning.
          </p>
          <img
            src={Preprimary}
            alt="geetanjali pre primary level"
            className="w-100"
          />
          <p className="text-justify">
            Pre-primary Level: This wing of the school is most important and
            resource calsses for school. Hundreds of tender kids are enrolled
            for their early education in Montessory based classes where a team
            of tdedicated, dynamic, loving and caring teachers with assistants
            are deployed to take care and nurture them in cosy environment.
          </p>
          <p className="text-justify">
            School level: This wing is the vertibral section of the institution
            where more than 700 students come to school and take education with
            a team of inspirational motherly teachers who impart grate knowledge
            with the spirit of dedication and devotion for flourishing better
            career and grate foundation in their lives. Geetanjali
            school/college management is aming to provide quality education in
            the ideal environment sorrounded with greenery. We believe, we must
            focus on developing individual capability and fundamental learning
            which can enrich a sense of achievement and enables our students to
            attend their school of their own.
          </p>

          <p className="text-justify">
            Finally, Geetanjali team urges our students to innovate the world of
            respect and newness in the sense of perfection and we are always
            ready to extend our intellectual support to promote humanity and
            sense of brotherhood in this common world.
          </p>
          <img
            src={College}
            alt="hotel management geetanjali"
            className="w-100"
          />
        </div>
      </div>
    </div>
  );
};

export default GeetanjaliManagementCollege;
