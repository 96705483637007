import Banner from "../Components/Banner";
import Message from "../Components/SchoollMessage";

const School = () => {
  return (
    <>
      <Banner title="School Level" />
      <Message />
    </>
  );
};

export default School;
