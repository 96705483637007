import React from "react";
import { CandidateElectionGallery } from "../DB/CandidateElectionImages";
import Fancybox from "../../Components/Fancybox";

const CandidateElection = () => {
  return (
    <>
      <div className="container my-5">
        <h4 className="title">Candidate Election: Jestha 14, 2081</h4>
        <p>
          Elected candidates for school captain, school vice captain, house
          captains and house vice captains for the academic session 2081 through
          the democratic process of election on 2081-02-14. Congratulations to
          all the elected candidates and we expect more from you in this
          session. Hope that you will lead Geetanjali to an innovative world.
        </p>

        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          {CandidateElectionGallery.map((list, index) => (
            <div className="col-md-3 col-12 gallery-image my-3">
              <a key={list.id} data-fancybox="gallery" href={list.image}>
                <img
                  src={list.image}
                  width="200"
                  height="150"
                  alt={`${list.id}-image`}
                />
              </a>
            </div>
          ))}
        </Fancybox>
      </div>
    </>
  );
};

export default CandidateElection;
