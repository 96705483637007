import one from "../../Images/SEEBasketBall/1.jpg";  
import two from "../../Images/SEEBasketBall/2.jpg";
import three from "../../Images/SEEBasketBall/3.jpg";
import four from "../../Images/SEEBasketBall/4.jpg";
import five from "../../Images/SEEBasketBall/5.jpg";
import six from "../../Images/SEEBasketBall/6.jpg";
import seven from "../../Images/SEEBasketBall/7.jpg";
import eight from "../../Images/SEEBasketBall/8.jpg";
import nine from "../../Images/SEEBasketBall/9.jpg";
import ten from "../../Images/SEEBasketBall/10.jpg";
import eleven from "../../Images/SEEBasketBall/11.jpg";
import twelve from "../../Images/SEEBasketBall/12.jpg";
import thirteen from "../../Images/SEEBasketBall/13.jpg";
import forteen from "../../Images/SEEBasketBall/14.jpg";
import fifteen from "../../Images/SEEBasketBall/15.jpg";
import sixteen from "../../Images/SEEBasketBall/16.jpg";
import seventeen from "../../Images/SEEBasketBall/17.jpg";
import eighteen from "../../Images/SEEBasketBall/18.jpg";
import nineteen from "../../Images/SEEBasketBall/19.jpg";
import twenty from "../../Images/SEEBasketBall/20.jpg";
import twentyone from "../../Images/SEEBasketBall/21.jpg";
import twentytwo from "../../Images/SEEBasketBall/22.jpg";
import twentythree from "../../Images/SEEBasketBall/23.jpg";

export const SEEBasketBallImages = [
  {
    id: 1,
    src: one,
  },
  {
    id: 2,
    src: two,
  },
  {
    id: 3,
    src: three,
  },
  {
    id: 4,
    src: four,
  },
  {
    id: 5,
    src: five,
  },
  {
    id: 6,
    src: six,
  },
  {
    id: 7,
    src: seven,
  },
  {
    id: 8,
    src: eight,
  },
  {
    id: 9,
    src: nine,
  },
  {
    id: 10,
    src: ten,
  },
  {
    id: 11,
    src: eleven,
  },
  {
    id: 12,
    src: twelve,
  },
  {
    id: 13,
    src: thirteen,
  },
  {
    id: 14,
    src: forteen,
  },
  {
    id: 15,
    src: fifteen,
  },
  {
    id: 16,
    src: sixteen,
  },
  {
    id: 17,
    src: seventeen,
  },
  {
    id: 18,
    src: eighteen,
  },
  {
    id: 19,
    src: nineteen,
  },
  {
    id: 20,
    src: twenty,
  },
  {
    id: 21,
    src: twentyone,
  },
  {
    id: 21,
    src: twentytwo,
  },
  {
    id: 23,
    src: twentythree,
  },
];
