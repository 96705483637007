import { Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import Logo from "../Images/logo.png";
import { Modal } from "react-bootstrap";
import { useState } from "react";

function Menu() {
  const [notificationModal, setNotificationModal] = useState(false);
  const [newsModal, setNewsModal] = useState(false);

  const navbar = document.querySelector(".navbar-collapse");
  const navbarCloseBtn = () => {
    if (navbar.classList.contains("show")) {
      navbar.classList.remove("show");
      console.log("test");
    }
  };

  const dropdownCloseBtn = () => {
    const dropdownMenu = document.querySelector(".dropdown-menu");
    if (dropdownMenu.classList.contains("show")) {
      dropdownMenu.classList.remove("show");
      navbar.classList.remove("show");
    }
  };

  function NotificationModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          New Academic Session for Grade 12 & Grade 11 Board Re-Exam 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            Dear Administrator, faculties and students, <br />
            You are notified that Geetanjali English Secondary School/
            College is starting <strong>new academic session for Class 12 and 
              Re-Exam of Class 11 Board exam
            </strong>{" "}
            from <strong>9th Ashad, 2081.</strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/exam-routine" target="_blank">
            <Button variant="primary">View Exam Routine</Button>
          </Link>
          <Button onClick={props.onHide} variant="light">
            Close
          </Button>
          <Link></Link>
        </Modal.Footer>
      </Modal>
    );
  }

  function NewsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">News </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-info">
            <h4>
              Rebuilding Lives After the November 3rd, 2023 Earthquake in
              Western Nepal - Bed Panta
            </h4>
            <a
              href="https://nepalnews.com/s/issues/rebuilding-lives-after-the-november-3rd-2023-earthquake-in-western-nepal"
              target="_blank"
            >
              <Button variant="primary">Read Full Article</Button>
            </a>
          </div>
          <div className="alert alert-info">
            <h4>
              The Second SEE Cup Basketball Tournament organized by Geetanjali
              School/College located in Nayabazar has been started from Jestha
              9,2081.
            </h4>
            <a
              href="https://bidharthi.com/content/2922?fbclid=IwZXh0bgNhZW0CMTEAAR2bakHTZBLnotVU3X7zTYhdZT15AFDrxCz39WLat691AN0gQqwAhPZY5So_aem_ZmFrZWR1bW15MTZieXRlcw"
              target="_blank"
            >
              <Button variant="primary">Read Full Article</Button>
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <div className="bg-light main-menu">
        <div className="container ">
          <Navbar expand="lg">
            <Navbar.Brand className="m-0">
              <Link to="/">
                <img src={Logo} alt="geetanjali school college" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link onClick={navbarCloseBtn}>
                  <Link to="/">Home</Link>
                </Nav.Link>

                <Link className="nav-link" to="/about" onClick={navbarCloseBtn}>
                  About
                </Link>

                <NavDropdown
                  title="Notice"
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                >
                  <Link to="/notice" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">Notice</NavItem>
                  </Link>
                  <Link to="/exam-routine" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">Routine</NavItem>
                  </Link>
                </NavDropdown>
                <NavDropdown
                  title="Academics"
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                >
                  <Link to="/pre-primary" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">Pre Primary</NavItem>
                  </Link>
                  <Link to="/school" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">School</NavItem>
                  </Link>
                  <Link to="/college" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">10+2</NavItem>
                  </Link>
                  <Link to="/bachelors" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">Bachelors</NavItem>
                  </Link>
                  <Link to="/gallery" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">Gallery</NavItem>
                  </Link>
                </NavDropdown>
                <NavDropdown
                  title="Downloads"
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                >
                  <Link to="/geetanjali-voice-2079" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">Geetanjali Voice</NavItem>
                  </Link>
                  <Link to="/calendar-2081" onClick={dropdownCloseBtn}>
                    <NavItem className="p-2">Geetanjali Calendar 2081</NavItem>
                  </Link>
                </NavDropdown>

                <Link
                  to="/contact"
                  onClick={navbarCloseBtn}
                  className="nav-link"
                >
                  Contact
                </Link>
                <Link
                  to="/result"
                  onClick={navbarCloseBtn}
                  className="nav-link"
                >
                  Result
                </Link>
              </Nav>
              <div className="topbar py-3">
                <ul className="d-flex justify-content-end flex-column flex-md-row gap-4">
                  <Nav.Link onClick={navbarCloseBtn}>
                    <Button
                      variant="light"
                      onClick={() => setNotificationModal(true)}
                    >
                      <li className="notification px-2">
                        <span className="notification-number">1</span>{" "}
                        <span>Notification</span>
                      </li>
                    </Button>
                  </Nav.Link>
                  <Nav.Link onClick={navbarCloseBtn}>
                    <Button variant="light" onClick={() => setNewsModal(true)}>
                      <li className="news px-2">
                        <span className="news-number">1</span>
                        News
                      </li>
                    </Button>
                  </Nav.Link>
                  <Link
                    to="/admission-form"
                    target="_blank"
                    onClick={navbarCloseBtn}
                  >
                    <li>
                      <Button variant="danger">Apply Online</Button>
                    </li>
                  </Link>
                </ul>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <NotificationModal
          show={notificationModal}
          onHide={() => setNotificationModal(false)}
        />
        <NewsModal show={newsModal} onHide={() => setNewsModal(false)} />
      </div>
    </>
  );
}

export default Menu;
