import { Button, Col, Container, Row } from "react-bootstrap";
import HeroImage from "../Images/graduate.png";
import AcademicCard from "../Components/AcademicCard";
import PrincipalMessage from "../Components/PrincipalMessage";
import Events from "../Events/Events";
import AOS from "aos";

import { useEffect } from "react";
import Faq from "../Components/Faq";
import HomePopup from "../Components/HomePopup";
import Article from "../Article/Article";
import BannerGallery from "../Components/BannerGallery";


const Home = () => {
  const subtitle = "Visit us for";
  const title = "Better, Professional";

  useEffect(() => {
    AOS.init();
  });

  return (
  <>
    <HomePopup />

      <div className="hero">
        <Container>
          <Row className="hero-container">
            <Col sm={12} md={12}>
              <div
                className="hero-content d-flex justify-content-center align-items-center flex-column"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <p className="mb-0" style={{ fontSize: "30px" }}>
                  {subtitle}
                </p>
                <h1 className="mb-3">
                  {title} <span className="text-dark">Career</span>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <section class="container my-5 number-counter">
          <ul>
            <li data-aos="fade-up" data-aos-duration="1000">
              <span class="numbers">60+</span>
              <p>Teaching Staffs</p>
            </li>
            <li data-aos="fade-up" data-aos-duration="1400">
              <span class="numbers">38</span>
              <p>Years of Excellence</p>
            </li>
            <li data-aos="fade-up" data-aos-duration="1800">
              <span class="numbers">900+</span>
              <p>Students</p>
            </li>
          </ul>
        </section>
      </Container>
      <BannerGallery />
      <AcademicCard />
      <PrincipalMessage />
      <Events />
      <Article />
      <Faq />
    </>
  );
};

export default Home;
