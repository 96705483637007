import React from "react";
import saraswatiPuja from "../../Images/Article/saraswati-puja-gess.jpeg";
import SEEBasketBallImg from "../../Images/SEEBasketBall/13.jpg";
import SEEBasketBallGallery from "./SEEBasketBallGallery";
import SEETrophy from "../../Images/SEEBasketBall/2.jpg";
import GroupAdmin from "../../Images/SEEBasketBall/14.jpg";
import { Link } from "react-router-dom";

const SEEBasketBall = () => {
  const shareUrl =
    "https://www.geetanjalischoolcollege.edu.np/see-basketball-2081-end";
  // Define Open Graph meta tags 
  const ogTitle =
    "Geetanjali Celebrates Saraswati Puja with Grandeur on Basant Panchami";
  const ogDescription =
    " On very auspicious occasion of Basant Panchami, devotees across Nepal and abroad celebrated Saraswati Puja with immense fervor.The early morning of the 2nd Falgun 2080 witnessed a gathering of students, scholars, teachers, guardians, and worshippers at Saraswati Temples nationwide, honoring the revered Goddess of Knowledge. Similar event happened in the premises of Geetanjali College, one of the vibrant educational institutions located in Khushibu, Town Planning, Nayabazar, Kathmandu valley.";
  const ogImageUrl = "../../Images/Article/saraswati-puja-gess.jpeg";
  return (
    <div className="container">
      <div className="article">
        <h1 className="article-title">
          SEE Geetanjali Basketball Tournament completed
        </h1>
        <span className="header-image">
          <img src={SEEBasketBallImg} alt="" />
        </span>
        <div className="article-content">
          <p>
            May, 11th Kathmandu. The 2nd SEE Cup Basketball Tournament of
            Geetanjali School/College located in Nayabazar has been completed.
            The basketball tournament, which started on the 9th of Jestha,
            has ended today(11th Jestha).
          </p>
          <p>
            Geetanjali School/College organized a basketball tournament for
            students who had passed SEE. Among the 16 teams participating in the
            basketball competition from all over Kathmandu, Royal Institute won
            the first place. Similarly, Greenfield School located in Bafal got
            the second place and Manaslu Public School located in Nayabazar got
            the third place.
          </p>
          <p>
            Royal Institute Subh Shrestha has received the best player award in
            the tournament. The first team in the competition was awarded with a cash
            prize of Rs. 7000, second team was awarded with a cash prize of Rs.5000 and
            the third team was awarded with a cash prize of Rs. 3,000 along with medals and
            certificates. Also, certificates were given to all participating teams.
          </p>
          <img src={SEETrophy} alt="trophy cerenomy" />
          <p>
            Speaking at the closing ceremony of the basketball tournament,
            Principal Bed Prasad Pant said that the best students in the
            basketball tournament will be given scholarships if they are
            enrolled in class 11.
          </p>
          <p>
            Geetanjali School/College has been organizing various extracurricular
            activities including sports for the all-round development of
            students. The college has also informed that a futsal competition
            will be held on Jestha 25.
          </p>
          <p>
            In addition, the winner of the Futsal competition will also get a
            chance to win cash prize of Rs.20,000 along with medal and certificates
            and it has been informed that the best students will be provided with a 
            full scholarship for admission to class 11.
            Futsal team registration is kept at Rs 3,000.
          </p>
          <p>
            The closing program of the basketball tournament was conducted by
            the school's coordinator and extracurricular activities department,
            Samir KC, while the closing ceremony was conducted under the
            chairmanship of the school/college president, Gobind Prasad Acharya.
          </p>
          <img src={GroupAdmin} alt="SEE Basketball tournament 2081" />
          <p>
            In the closing ceremony, Geetanjali School/College Finance Director
            Bhimprasad Bhattarai, Coordinator Basudev Adhikari and other
            teachers and staff were present.
          </p>
          <p>
            Article by:{" "}
            <Link to="https://bidharthi.com/content/2925" target="_blank">
              www.bidharthi.com
            </Link>
          </p>
          <p>
            Click read more button to read more detail about the competition
            <br />
            <Link to="https://bidharthi.com/content/2925" target="_blank">
              <button className="btn btn-sm btn-primary">Read More</button>
            </Link>
          </p>
        </div>
      </div>

      <SEEBasketBallGallery />
    </div>
  );
};

export default SEEBasketBall;
