import { useState } from "react";
import PrincipalImage from "../Images/principal.jpg";

const PrincipalMessage = () => {
  const [isExpand, setIsExpand] = useState(false);
  const readMoreBtn = () => {
    setIsExpand(!isExpand);
  };
  const [message] = useState([
    {
      principalName: "Mr. Bed Prasad Panta",
      principalImage: PrincipalImage,
      title: "Message from principal",
      subtitle: "Dear Parents, guardians, students and well-wishers,",
      greeting: "very warm greetings !",
      messagePara1:
        "Education develops conscience to differentiate right and wrong, to enable one to make the right decisions to make one’s behavior exemplary and ideal cultivate humanity and morality, and enhance one’s desired talents. Education makes a person ideal and leads to righteousness so Geetanjali school /college aims to foster a next-generation leader to play an effective role in a global scenario. The school /college management strives to upgrade the quality, language, discipline, attitude, and studious environment to encourage our students to develop their professionalism to be a global leader. The college tends to equip them with keen enthusiasm, intelligence, dynamism, and proficiency in overall activities. So an immense pleasure emotes me to welcome all the students from playgroup to bachelor level in Geetanjali for quality. cultured and life skill-based education. I assure you that our team of academics with passion, zeal, and enthusiasm is working all hours for academic excellence. Moreover, our students get hands-on experience via continuous exposure and insights from academicians and socialite figures. Our vibrant team is dedicated to innovating modern teaching pedagogy and promoting monastic infrastructures and stimulating beautiful panoramic sceneries in the college premisses. I easily guess the best peaceful environment within the school/college premises will certainly lure our students and teaching faculties to achieve their goals and parents to quench the thirst for finding the best school/college in Kathmandu.",

      messagePara2:
        "I believe that the teaching faculties are motivators, demonstrators, and real guides to a student in an academic institution. There are different categories of teachers on the basis of teaching pedagogy- a mediocre teacher who just tells about the textbook and a good teacher always explains the subject matter whereas the superior teacher simplifies the subject matter and demonstrates. Moreover, a great teacher inspires the students. So, it is clear that the teacher who teaches without inspiration is like hammering on cold iron. That’s why we encourage our teaching faculties to inspire our students.",

      messagePara3:
        "This era is quite challenging so there is tough competition everywhere. Our ultimate goal is to make our students smart, competitive, professional, and proactive. So we urge every individual here to get more done in less time. Geetanjali aspires to the slogan “learn with fun” and the motto “leading to the innovative world” so why our team is dedicated to creating an environment where learning becomes fun. The college follows scientific-pedagogical techniques, participatory methodology, and audio-visual presentations. In addition, Geetanjali is a place where students and teachers work together to explore the unknown in order to make their dreams come true.",

      messagePara4:
        " We hang on for personal and inter-personal prosperity ignoring our tireless effort. It is possible only through education. Education makes it happen- a farmer’s son becomes an engineer or a sweeper’s daughter- a doctor and an ordinary person become the President or the Prime Minister that’s why Education has no substitute at all.",

      messagePara5:
        "Finally, I would like to express my sincere gratitude to all the students, their parents/guardians, and well-wishers for the constructive suggestions, positive criticisms, and frequent interactions.",
    },
  ]);

  return (
    <section>
      <div className="container my-5">
        <div>
          {message.map((messages) => (
            <>
              <div className="row principal-message mb-4">
                <div className="col-auto">
                  <img
                    src={messages.principalImage}
                    alt="geetanjali school college"
                  />
                </div>
                <div className="col">
                  <h4 className="fw-bold">{messages.title}</h4>
                  <p className="m-0 text-muted">{messages.subtitle}</p>
                  <p className="text-muted">{messages.greeting}</p>
                </div>
              </div>
              <div className={`content ${isExpand ? "expanded" : "collapsed"}`}>
                <p className="text-justify">{messages.messagePara1}</p>
                <p className="text-justify">{messages.messagePara2}</p>
                <p className="text-justify">{messages.messagePara3}</p>
                <p className="text-justify">{messages.messagePara4}</p>
                <p className="text-justify">{messages.messagePara5}</p>
                <p className="fw-bold">God bless Geetanjalians!</p>
                <p className="fw-bold">Principal</p>
                <p className="fw-bold">Mr. Bed Prasad Panta</p>
              </div>
              <button
                className="btn btn-danger principal-message-btn mt-2"
                onClick={readMoreBtn}
              >
                {isExpand ? "Read Less" : "Read More"}
              </button>
            </>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PrincipalMessage;
