import React from "react";
import Fancybox from "../../Components/Fancybox";
import { SEEFutsalImages } from "../DB/SEEFutsalImages";

const SEEFutsalGallery = () => {
  return (
    <>
      <div className="container my-5">
        <h1 className="mb-5">GEETANJALI SEE CUP- 2081 One-Day Knockout Futsal Tournament</h1>
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          {SEEFutsalImages.map((list, index) => (
            <div className="col-md-3 col-12 gallery-image my-3">
              <a key={list.id} data-fancybox="gallery" href={list.src}>
                <img
                  src={list.src}
                  width="200"
                  height="150"
                  alt={`${list.id}-image`}
                />
              </a>
            </div>
          ))}
        </Fancybox>
      </div>
    </>
  );
};

export default SEEFutsalGallery;
