import hotelManagement from "../Images/hotel-management.jpg";
import rafting from "../Images/rafting.jpg";
import hiking from "../Images/hiking.webp";
import computerLab from "../Images/computer-lab.jpg";
import studentJump from "../Images/stunde-jumping.jpg";
import music from "../Images/music.jpg";
import basketballTeam from "../Images/basketball-team.jpg";
import danceWinner from "../Images/danceWinner.jpg";
import preprimaryholi from "../Images/pre-primary-holi.jpg";
import karate from "../Images/karate.jpg";
import raftingCollege from "../Images/rafting-2.jpg";
import holiSchool from "../Images/holi.jpg";

export const ImagesBD = [
  {
    id: 1,
    src: hotelManagement,
  },
  {
    id: 2,
    src: computerLab,
  },
  {
    id: 3,
    src: hiking,
  },
  {
    id: 4,
    src: raftingCollege,
  },
  {
    id: 5,
    src: studentJump,
  },
  {
    id: 6,
    src: music,
  },
  {
    id: 7,
    src: basketballTeam,
  },
  {
    id: 8,
    src: danceWinner,
  },
  {
    id: 9,
    src: preprimaryholi,
  },
  {
    id: 10,
    src: karate,
  },
];

export const GalleryImageDB = [
  {
    id: 1,
    src: hotelManagement,
  },
  {
    id: 2,
    src: computerLab,
  },
  {
    id: 3,
    src: hiking,
  },
  {
    id: 4,
    src: rafting,
  },
  {
    id: 5,
    src: studentJump,
  },
  {
    id: 6,
    src: music,
  },
  {
    id: 7,
    src: basketballTeam,
  },
  {
    id: 8,
    src: danceWinner,
  },
  {
    id: 9,
    src: preprimaryholi,
  },
  {
    id: 10,
    src: karate,
  },
  {
    id: 11,
    src: raftingCollege,
  },
  {
    id: 12,
    src: holiSchool,
  },
];
