import { React, useState, useEffect } from "react";
import Banner from "../Components/Banner";
import { Container, Button } from "react-bootstrap";
import AOS from "aos";
import Preprimary from "../Images/classes/pre-primary.webp";
import computerLab from "../Images/computer-lab.jpg";
import rafting from "../Images/rafting.jpg";
import hotelManagement from "../Images/hotel-management.jpg";
import music from "../Images/music.jpg";
import hiking from "../Images/hiking.webp";

function About() {
  const [isExpand, setIsExpand] = useState(false);

  const toggleContent = () => {
    setIsExpand(!isExpand);
  };

  useEffect(() => {
    AOS.init();
  });

  const title = "A Glimpse Of Geetanjali";
  return (
    <>
      <Banner title="About us" />
      <Container className="py-5">
        <h3 className="text-danger" data-aos="fade-down">
          {title}
        </h3>
        <div className="article-content">
          <p className="text-justify">
            Geetanjali school/college is named after the holy scriptures by
            Rabindra Nath Tagore- a noble prize winner literary piece
            “Geetanjali” hoping to brand its name and fame. This is a
            co-educational institution where students from play group to
            bachelor level can study with various subjects of their interest and
            choice in the same premises under parental guidance of devoted
            teaching faculties and administrative experts. To attain this
            academic, social, physical, and infrastructural height, Geetanjli
            has striven a lot in the respective fields for more than three
            decades so far. The school wing was established in 2043 B.S. (1986
            A.D) only up to grade five, and it was gradually upgraded to
            Bachelors’ level in 2065 B.S. The year “2059” B.S. was a very
            remarkable year as the school could appear in national level
            examinations called S.L.C. (School Leaving Certificate) – simply
            taken an iron gate for further studies. The development of the
            institution did not stop only at SLC, rather, the management
            committee started +2 (i.e. 10+2) in 2062 B.S. and later started
            bachelor level in 2065 B.S. During this period of more than three
            decades, the institution has experienced ups and downs and has now
            been quite experienced to understand the government policy, to go
            with parents’ aspiration and understand and fulfill students demands
            and quite enough able to cope with the challenges.
          </p>
          <p className="text-justify">
            Now the institution runs from 6:00 AM to 4:15 PM at full strength -
            6 days a week except on public holidays. Bachelor and +2 classes run
            during the morning hours (6:00 AM to 11: 00 AM) and pre-school and
            school run during daytime (9:30 AM to 4:15 PM). Geetanjali has run
            Business Studies courses- a fundamental theoretical course that
            links to the applied business arena at bachelor level. And in +2
            level, various subjects like Accounting, Economics, Hotel
            Management, Computer Science, Tourism, Business Studies, Mass
            Communication, Sociology, Rural Development, Major English, Major
            Nepali, Major Mathematics, Law, Music are extra additional courses
            that add a basic foundation for high school students. About 900
            students graduate from this institution every year, which is a
            matter of pride for our glorious institution. The school/college
            owns a hygiene cafeteria, a spacious playground, a volleyball and
            basketball court, a table tennis station, a badminton court and an
            auditorium hall, which are its essential extra ornamental assets.
            Besides that, we have a library, computer lab, hotel management lab
            and science laboratory as a part of regular study and learning.
          </p>
          <img
            src={Preprimary}
            alt="geetanjali pre primary level"
            className="w-100"
          />
          <p className="text-justify">
            <span className="fw-bold">Pre-primary Level:</span> This wing of the
            school is the most important and resource classes for school.
            Hundreds of tender kids are enrolled for their early education in
            Montessori- based classes where a team of dedicated, dynamic, loving
            and caring teachers with assistants are deployed to take care of and
            nurture them in a cozy environment.
          </p>
          <p className="text-justify">
            <span className="fw-bold">School Level:</span> This wing is the
            vertebral section of the institution where more than 500 students
            come to school and take education with a team of inspirational,
            loving teachers who impart great knowledge with the spirit of
            dedication and devotion to flourishing a better career and a great
            foundation in their lives. Geetanjali school/college management aims
            to provide quality education in an ideal environment surrounded by
            greenery. We believe we must focus on developing individual
            abilities and fundamental learning which can enrich a sense of
            achievement and enable our students to attend a school of their own.
          </p>

          <p className="text-justify">
            <span className="fw-bold">+2 Level:</span> This wing of the whole
            institution stands as an ornamental part of Geetanjali College, an
            excellent institution in management education. With a diverse
            spectrum of various subjects ranging from Hotel Management to
            Computer Science, Tourism &amp; Mountaineering to Business Studies,
            Accountancy, and Economics, Geetanjali College offers a
            comprehensive and dynamic learning environment for aspiring managers
            and leaders.
          </p>
          <img
            src={hotelManagement}
            alt="hotel management geetanjali"
            className="w-100"
          />
          <p className="text-justify">
            <span className="fw-bold">Hotel Management Program:</span>{" "}
            Magnificent Infrastructure and State-of-the-Art Facilities:
            Geetanjali College is proud of its impressive facilities, which are
            intended to help students grow holistically and engage in
            experiential learning. The well-equipped Hotel Management Lab,
            located at the forefront of the campus, allows students to practice
            various hospitality management skills in real-world scenarios.
          </p>
          <p className="text-justify">
            The college takes great pride in its practical, course-based
            instruction, which emphasizes skill-oriented teaching strategies to
            prepare students for the demands of today&#39;s workforce. The
            curriculum includes essential components such as teaching
            housekeeping skills, front office training, and regular kitchen
            practical sessions. These ensure that students graduate with a
            thorough understanding of the operational aspects of the hospitality
            industry.
          </p>
          <p className="text-justify">
            Moreover, Geetanjali College facilitates hotel visits and offers
            need-based training programs related to the hospitality sector,
            providing students with invaluable insights and exposure to
            industry-based practices. The college takes great satisfaction in
            its course-based, hands-on instruction, which emphasizes
            skill-oriented teaching approaches that equip students for the
            demands of the contemporary workforce. To guarantee that students
            graduate with a thorough understanding of the operational aspects of
            the hospitality industry, regular kitchen practical sessions, front
            office training, and housekeeping skill instruction are essential
            components of the curriculum.
          </p>
          <img
            src={computerLab}
            alt="computer lab geetanjali"
            className="w-100"
          />
          <h4 className="title">Computer Science in Geetanjali College</h4>
          <p className="text-justify">
            The college&#39;s cutting-edge Computer Lab is a prime example of
            its dedication to technological excellence in the field of computer
            science. The lab, which is furnished with the newest models of
            computers and cutting-edge software, acts as a vibrant center for
            creativity, teamwork, and practical education. Geetanjali
            College&#39;s computer science curriculum requires proficiency with
            office packages and desktop handling. Students gain knowledge of
            operating systems and how to apply theoretical ideas in practical
            settings. Students enhance their critical thinking abilities and
            obtain a more profound comprehension of computer systems and
            programming languages by means of practical experiments and
            problem-solving activities.  Furthermore, Geetanjali College offers
            its computer science students training for pilot projects that go
            beyond traditional coursework, preparing them for careers in the
            field. The college offers advice and support to help students
            discover their interests and pursue relevant opportunities in fields
            like software development, cybersecurity, data science, and more, in
            recognition of the variety of career paths available in the
            technology sector.
          </p>
          <p className="text-justify">
            The college offers specialized courses in web page design in
            addition to core competencies, reflecting the growing significance
            of digital presence and user experience in today&#39;s connected
            world.
          </p>
          <p className="text-justify">
            Students learn how to create visually appealing and user-friendly
            websites that adhere to industry standards and best practices
            through practical projects and design workshops. Geetanjali College
            equips students to meet the dynamic challenges of the computing
            field with competence and confidence by fusing theory and practice.
             In conclusion, Geetanjali College&#39;s Computer Science program
            stands as a symbol of excellence, providing students with the tools,
            knowledge, and practical skills needed to thrive in the dynamic and
            fast-paced world of technology. With its state-of-the-art
            facilities, tailored training programs, and focus on practical
            application, the college empowers students to become innovators,
            problem solvers, and leaders in the emerging world.
          </p>
          <img src={rafting} alt="rafting geetanjali" className="w-100" />
          <h4 className="title">Tourism and Mountaineering Program</h4>
          <p className="text-justify">
            Geetanjali College&#39;s Tourism and Mountaineering program exposes
            students to Nepal&#39;s stunning scenery, dynamic cultures, and rich
            traditions while also serving as a transforming educational
            experience. The program offers students immersive experiences that
            promote personal growth, cultural appreciation, and professional
            development, going beyond the limitations of traditional classroom
            instruction. It does this by having a strong commitment to
            experimental learning.
          </p>
          <p className="text-justify">
            The program&#39;s main component, field trips, gives students a
            firsthand understanding of the complexities of mountaineering and
            tourism management. From trekking through verdant forests and
            majestic mountain ranges to discovering ancient temples and UNESCO
            World Heritage Sites, students go on life-changing adventures that
            broaden their perspectives and enhance their comprehension of
            Nepal&#39;s rich culture. Students receive invaluable insights into
            responsible travel ethics, sustainable tourism practices, and the
            value of maintaining cultural authenticity through their
            interactions with local communities. Students gain knowledge of the
            economic, social, and environmental effects of tourism on local
            communities through interactions with artisans, business owners, and
            tourism professionals. This knowledge equips them to support moral
            and sustainable tourism practices in their future undertakings.
          </p>
          <img
            src={hiking}
            alt="geetanjali students hiking"
            className="w-100"
          />
          <p className="text-justify">
            Additionally, the Geetanjali College Tourism and Mountaineering
            program cultivates a spirit of exploration and adventure while
            imparting resilience, tenacity, and adaptability in its students.
            Through experiences such as scaling mountains, negotiating difficult
            terrain, or connecting with other travelers, students acquire vital
            life skills that go beyond the classroom and equip them for success
            in an increasingly interconnected world.
          </p>
          <p className="text-justify">
            Essentially, the Geetanjali College program is a life-changing
            experience that encourages students to become protectors of
            Nepal&#39;s rich cultural heritage, promoters of eco-friendly
            travel, and stewards of the country&#39;s natural beauty. The
            program equips students to pursue a lifetime of exploration,
            discovery, and cross-cultural exchange in one of the most alluring
            locations on earth by fusing academic rigor with hands-on learning.
            The program empowers students to embark on a lifelong pursuit of
            discovery, exploration, and cultural exchange in one of the
            world&#39;s most captivating destinations.
          </p>
          <h4 className="title">Business Studies and beyond</h4>
          <p className="text-justify">
            Geetanjali College takes pride in fostering the next wave of
            innovators and leaders in the field of business studies. With a
            thorough curriculum that covers market strategies, business
            practices, and financial dynamics, students are prepared with the
            knowledge and skills necessary to successfully negotiate the
            challenges of the global marketplace. In a variety of industries,
            students are equipped to lead sustainable growth and innovation,
            from strategic business planning to local market development.
            Geetanjali College is dedicated to promoting a culture of
            professional development and networking in addition to academics.
            Students can connect with industry experts, explore internship
            opportunities, and gain real-world experience in their chosen fields
            through the college&#39;s alumni networks, research initiatives, and
            strategic partnerships with industry leaders. To sum up, Geetanjali
            College is a bright example of excellence in management education,
            providing students with a life-changing education that equips them
            to succeed in a fast-paced, cutthroat world. With its
            state-of-the-art facilities, impressive infrastructure, and
            steadfast dedication to student success, the college is still
            setting the bar for excellence in the Kathmandu Valley and beyond.
          </p>
          <img src={music} alt="music in college" className="w-100" />

          <h4 className="title">Music in +2</h4>
          <p className="text-justify">
            Beyond melodies, Geetanjali&#39;s music stream at the +2 level
            offers comfort, development, and inspiration. Music has the power to
            heal and uplift people beyond boundaries. With world-class teachers
            and a cutting-edge music lab, students experience an unbounded
            creative environment. Discipline, cooperation, and emotional
            expression are all fostered by music and are essential life skills.
            It fosters empathy, understanding, and a respect for different
            cultures in addition to the notes. Geetanjali acknowledges the
            profound influence of music, not only in molding musicians but also
            in creating kind, strong people who can face any challenge with a
            song in their hearts.
          </p>
          <h4 className="title">Conclusion</h4>
          <p className="text-justify">
            Finally, the Geetanjali team exhorts our students to create a world
            that is both innovative and respectful in the sense of perfection.
            We are also always willing to offer our intellectual support to
            advance humanity and a sense of fraternity in this shared world.
            Fundamentally, Geetanjali College is a model of excellence in
            management education, providing a wide range of programs that are
            specifically designed to satisfy the changing demands of the
            fast-paced market of today. With its cutting-edge facilities,
            world-class infrastructure, and dedication to experiential learning,
            the college develops the next generation of leaders, innovators, and
            changemakers who are ready to positively influence society and the
            global economy. With the ever-changing field of management
            education, Geetanjali College never wavers in its commitment to
            providing students with the skills.
          </p>
        </div>
      </Container>
    </>
  );
}

export default About;
