import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Grade11Routine = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(grade, first, second,third) {
    return {
      grade,
      first,
      second,
      third,
    };
  }

  const rows = [
    createData("Ashad 9, 2081", "Sunday", "Nepali","7:00-10:00 AM"),
    createData("Ashad 10, 2081", "Monday", " English","7:00-10:00 AM"),
    createData("Ashad 11, 2081", "Tuesday", "Social/Mathematics","7:00-10:00 AM"),
    createData("Ashad 12, 2081", "Wednesday", "Accounting/Sociology","7:00-10:00 AM"),
    createData("Ashad 13, 2081", "Thursday", "Economics/Mass Com./Tourism","7:00-10:00 AM"),
    createData("Ashad 14, 2081", "Friday", "Business/CS/HM/M.English/Music","7:00-10:00 AM"),
  ];
  return (
    <div className="my-5 exam-routine">
      <h4>Final Result Announcement and Further Instructions</h4>
      <p className="text-justify">
        Dear Administrator, faculties and students, <br />
        We are pleased to announce that the final results for Grade 11 have been published. 
        The results are now available for viewing on the school noticeboard and on the school's official website.<br />
       
      </p>
      <p className="text-justify">
        All the functioning departments are asked to prepare and work for the
        examination procedure smartly.
      </p>
      <p className="text-justify">
      <strong> Instructions for Students Who Passed: </strong> <br />
      Students who have successfully completed Grade 11 are required to complete their admission process for Grade 12 by the 9th of Asar, 2081.
       Please ensure all necessary procedures and fees are submitted by this date to secure your enrollment and avoid all sorts of penalties. <br />

    </p>
    <p className="text-justify">
    <strong>Instructions for Students Who Did Not Pass:  </strong> <br />
    Students who have not passed will have the opportunity to appear for a chance exam. 
    They must fill in the chance exam form by  paying Rs. 200 up to two subjects and Rs. 200 each will be added if failed more than two subjects.<br />
     The details of the chance exam schedule are as follows:

  </p>
      
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow className="table-head">
              <StyledTableCell style={{ textAlign: "left" }}>
                Exam Date
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }}>
                Day
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }}>
                Subjects
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }}>
                Time
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.grade}
                </StyledTableCell>
                <StyledTableCell align="">{row.first}</StyledTableCell>
                <StyledTableCell align="">{row.second}</StyledTableCell>
                <StyledTableCell align="">{row.third}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="alert alert-info mt-3">
        
         
        
        <p>
        Please check the detailed schedule on the school notice board or the school's official website. 
          
          
          For any queries or further assistance, feel free to contact the school administration office during working hours.
        </p>
          
        <p>
        We wish all our students the very best for their future endeavors.<br />
        Note: Grade 12 classes resume from 9th Asar 2081, so all the students are asked
         to be  present on the first day with fresh energy and enthusiasm. <br /> <strong>Thank you!</strong> <br />
          <strong>Principal</strong> <br /> Bed Prasad Panta
        </p>
      </div>
    </div>
  );
};

export default Grade11Routine;
