import React, { useState } from "react";

const AdmissionPage = () => {
  const [collegeForm, setCollegeForm] = useState(true);
  const [bachelorsForm, setBachelorsForm] = useState(false);

  const collegeFormBtn = () => {
    setCollegeForm(true);
    setBachelorsForm(false);
  };
  const bachelorsFormBtn = () => {
    setCollegeForm(false);
    setBachelorsForm(true);
  };

  return (
    <div>
      <div className="container py-5">
        <h4 className="title">Admission Forms</h4>
        <div className="d-flex gap-3">
          <button
            className={`btn btn-md ${
              collegeForm ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={collegeFormBtn}
          >
            10+2 Level
          </button>
          <button
            className={`btn btn-md ${
              collegeForm ? "btn-outline-primary" : "btn-primary"
            }`}
            onClick={bachelorsFormBtn}
          >
            Bachelors Level
          </button>
        </div>

        {collegeForm && (
          <div className="college-form py-3">
            <p>
              Ready to take the next step in your academic journey? Use the form
              below to apply for admission to your chosen program. Fill it out
              accurately, and our team will guide you through the process.
              Excited to have you join our community!
            </p>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdvwkJ5mFrZPMN6F1wz0sSuoowJ_VWwjWjhMU8xj7BvKlQQAA/viewform?embedded=true"
              width="100%"
              height="2000"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </div>
        )}

        {bachelorsForm && (
          <div className="bachelors-form py-3">
            <p>
              Ready to kickstart your undergraduate adventure? This form is your
              ticket to joining the program you've set your sights on. Complete
              it below, and let's pave the way for your academic success
              together!
            </p>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSeJLhRJM5s_5RVsztboLdPr1Cwf8b7hroa2bts4zbbI5MDs6Q/viewform?embedded=true"
              width="100%"
              height="1800"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdmissionPage;
