import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Result11 = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(grade, first) {
    return {
      grade,
      first,
      
    };
  }

  const rows = [
    createData("Aareka Sheikh", "12738109"),
    createData("Aaryan Rai", "12738110"),
    createData(" Alina Budhathoki", "12738114"),
    createData("Alisha Tamang", "12738115"),
    createData("Anisha BK", "12738117" ),
    createData("Anita Sen", "12738118" ),
    createData("Anshu Kumari ", "12738119"),
    createData("Ansu Chaulagain", "12738120"),
    createData(" Balaram Chauhan", "12738122"),
    createData("Bibek Budha", "12738126"),
    createData("Bikal Thapa ", "12738127" ),
    createData("Binod Sunar", "12738128" ),
    createData("Bipana Tamang", "12738129"),
    createData("Biraj Rana Magar", "12738130"),
    createData("Biseshta Putuwar", "12738131"),
    createData("Bishakha Sunar", "12738132"),
    createData("Deebas Pariyar", "12738134" ),
    createData("Ganga Gole", "12738139" ),
    createData("Gautam Rai", "12738140"),
    createData("Jeena Maharjan", "12738143"),
    createData("Jipsan Shrestha", "12738144"),
    createData("Joshna Khatri", "12738145"),
    createData("Kiran Sedhain", "12738147" ),
    createData("Koshika Pariyar", "12738148" ),
    createData("Kritika Itani", "12738149"),
    createData("Kusal Dulal", "12738150"),
    createData("Laxmi Lama", "12738151"),
    createData("Masum Babu Ansari", "12738153"),
    createData("Menuka Ale Magar", "12738154" ),
    createData("Milan Tamang", "12738155" ),
    createData("Mira Thapa Magar", "12738156"),
    createData("Naresh Bhattarai", "12738157"),
    createData("Nawal Kishwor Mukhiya", "12738158"),
    createData("Nayan Rijal", "12738159"),
    createData("Pradip Mukti Das", "12738160" ),
    createData("Prajal Malbul", "12738161" ),
    createData("Prakash Magar", "12738162" ),
    createData("Pramosh Gurung", "12738163"),
    createData("Pratha Dhamala", "12738164"),
    createData("Rakita Khadka", "12738169"),
    createData("Renuka Dahal", "12738170"),
    createData("Rosan Gurung", "12738171" ),
    createData("Roshni Ghimire", "12738172" ),
    createData("Rupa Darji", "12738173"),
    createData("Rushal Khadgi", "12738175"),
    createData("Sabin Basnet", "12738176"),
    createData("Sabina Timalsina", "12738177"),
    createData("Sadikshya Acharya", "12738179" ),
    createData("Salina Sorali Magar", "12738180" ),
    createData("Samrat Mijar", "12738183" ),
    createData("Sandip Adhikari", "12738184"),
    createData("Sapana Km Thakur", "12738187"),
    createData("Saraswoti Maya Rai", "12738188"),
    createData("Sarmila Sen", "12738189"),
    createData("Saugat Achhami", "12738190" ),
    createData("Shambhu Bhujel", "12738192" ),
    createData("Shuwashna Silwal", "12738194"),
    createData("Siddhartha Bomjan", "12738195"),
    createData("Sonam Tamang", "12738198"),
    createData("Soniya Tamang", "12738199"),
    createData("Sosi Putuwar", "12738200" ),
    createData("Sugam Regmi", "12738203" ),
    createData("Sujan Pariyar", "12738204" ),
    createData("Sumit Gurung", "12738206" ),
    createData("Sunita Waiba", "12738207" ),
    createData("Sushma Tamang", "12738210" ),
    createData("Sushmita Tamang", "12738211" ),
    createData("Swetaa Shrestha", "12738212" ),
    createData("Tulasi Bishwakarma", "12738213" ),
    createData("Yarika Pulami", "12738215" ),
    createData("Yogesh Pulami Magar", "12738216" ),
  ];
  return (
    <div className="my-5 exam-routine">
      <h4>Result Published : Grade 11</h4>
      <p className="text-justify">
        Dear Administrator, faculties and students, <br />
        The exam committee Geetanjali +2 wing has decided to publish result dated on 2081/03/05 as purposed. 
        Those students who have passed Grade 11 can proceed for Grade 12 admission procedure and students failing have to prepare for chance exam that is scheduled from 2081/03/09.<br />
       
      </p>
      <strong> Passed Students List</strong>
      
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow className="table-head">
              <StyledTableCell style={{ textAlign: "left" }}>
                Student Name
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }}>
                Symbol No
              </StyledTableCell>
             
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.grade}
                </StyledTableCell>
                <StyledTableCell align="">{row.first}</StyledTableCell>
               
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="alert alert-info mt-3">
        
         
        
        <p>
        Please check the detailed schedule on the school notice board or the school's official website. 
          
          
          For any queries or further assistance, feel free to contact the school administration office during working hours.
        </p>
          
        <p>
        We wish all our students the very best for their future endeavors.<br />
        Note: Grade 12 classes resume from 9th Asar 2081, so all the students are asked
         to be  present on the first day with fresh energy and enthusiasm. <br /> <strong>Thank you!</strong> <br />
          <strong>Principal</strong> <br /> Bed Prasad Panta
        </p>
      </div>
    </div>
  );
};

export default Result11;
