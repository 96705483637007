import React from "react";
import Banner from "../Components/Banner";
import CollegeMessage from "../Components/CollegeMessage";

const College = () => {
  return (
    <>
      <div className="college-banner">
        <span className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-warning">10+2 Level</h1>
          <p className="text-white p-2">
            +2 Management is a two-year course designed to set up a foundation
            on management studies
          </p>
        </span>
      </div>
      <CollegeMessage />
    </>
  );
};

export default College;
