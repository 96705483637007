import React from "react";
import pdf from "../Images/Broucher/GESSVoice2079.pdf";

const GeetanjaliVoice = () => {
  return (
    <div className="my-5 pdf-view">
      <h4 className="text-center">Geetanjali Voice 2079</h4>
      <iframe src={pdf} frameborder="0"></iframe>
    </div>
  );
};

export default GeetanjaliVoice;
