import { Container } from "react-bootstrap";
import ErrorImage from "../Images/error.jpg";

const ErrorPage = () => {
  const title = "404 Error, Page Not Found";
  return (
    <>
      <Container className="error-page-container d-flex align-items-center pt-5 flex-column flex-md-row">
        <img src={ErrorImage} alt="error page" />
        <div className="d-flex justify-content-center flex-column">
          <div className="alert alert-danger">
            <h4 className="text-danger text-center">{title}</h4>
            <div className="d-flex align-items-center gap-2 justify-content-center">
              <i class="fa-solid fa-triangle-exclamation fa-bounce text-danger"></i>
              <p className="text-center m-0 text-danger">Please check url</p>
            </div>
          </div>
          <button className="btn btn-outline-success">
            Go Back To Homepage
          </button>
        </div>
      </Container>
    </>
  );
};

export default ErrorPage;
