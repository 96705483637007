import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Image from "../Images/header3.jpeg";
import CollegeImage from "../Images/classes/college.webp";
import PrePrimary from "../Images/classes/pre-primary.webp";
import AOS from "aos";

function AcademicCard() {
  const [classList] = useState([
    {
      title: "School",
      link: "https://www.geetanjalischoolcollege.edu.np/#/school",
      formlink: "https://www.geetanjalischoolcollege.edu.np/#/admission-form",
      image: PrePrimary,
      id: 1,
    },
    {
      title: "Plus 2",
      link: "https://www.geetanjalischoolcollege.edu.np/#/college",
      formlink: "https://www.geetanjalischoolcollege.edu.np/#/admission-form",
      image: CollegeImage,
      id: 2,
    },
    {
      title: "Bachelors",
      link: "https://www.geetanjalischoolcollege.edu.np/#/bachelors",
      formlink: "https://www.geetanjalischoolcollege.edu.np/#/admission-form",
      image: Image,
      id: 3,
    },
  ]);

  const header = "ACADEMICS";
  const subheader = "The Minds Of Tomorrow";

  useEffect(() => {
    AOS.init();
  });

  return (
    <Container className="my-5">
      <h4
        className="text-center text-danger title"
        data-aos="fade-down"
        data-aos-duration="2000"
        data-aos-anchor-placement="bottom-bottom"
      >
        {header}
      </h4>
      <p
        className="text-center sub-title"
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-anchor-placement="bottom-bottom"
      >
        {subheader}
      </p>
      <Row className="d-flex justify-content-center justify-content-md-between gap-4">
        {classList.map((classListCards) => (
          <div
            className="col-md col-sm-12 card  class-card p-0"
            key={classListCards.id}
          >
            <div className="class-card-image">
              <img src={classListCards.image} alt="" />
              <div className="class-card-content">
                <div className="card-title fw-bold">
                  <h4 className="fw-bold">{classListCards.title}</h4>
                </div>
                <div className="card-btn d-flex  gap-2">
                  <a
                    href={classListCards.link}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-warning"
                  >
                    View Detail
                  </a>
                  <a
                    href={classListCards.formlink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-outline-warning"
                  >
                    Apply online
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Row>
    </Container>
  );
}

export default AcademicCard;
