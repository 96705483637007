import React from "react";
import Grade12Routine from "../Components/ExamRoutine/Grade12Routine";

const Grade12ExamRoutine = () => {
  return (
    <div>
      <Grade12Routine />
    </div>
  );
};

export default Grade12ExamRoutine;