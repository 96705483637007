import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Components/CommonBanner";
import AOS from "aos";
import { useEffect } from "react";
import LocationMap from "../Components/LocationMap";
import ContactForm from "../Components/ContactForm";

const Contact = () => {
  useEffect(() => {
    AOS.init();
  });

  const title = "Contact us for more information";
  return (
    <div>
      <CommonBanner
        title="Contact Page"
        data-aos="fade-up"
        data-duration-aos="1000"
      />
      <Container>
        <h4 className="py-5">{title}</h4>
        <Row>
          <Col lg={12}>
            <LocationMap />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
