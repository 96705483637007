import React, { useState } from "react";
import Event1 from "../Images/Events/volleyball-game.jpeg";
import EventSwizerland from "../Images/Events/swizerland-park.jpg";
import PrizeDistribution from "../Images/Events/geetanjalisports.jpg";
import SEEBasketBall from "../Images/Events/gess-basketball-competition.webp";
import SEEBasketBallImg from "../Images/SEEBasketBall/2.jpg";
import SEECupFutsaltournamaent from "../Images/fustal/all.jpg";
import CandidateElection from "../Images/Events/CandidateElection/1.jpg";
import { Link } from "react-router-dom";


function Events() {
  const header = "Events";
  const [events, setEvents] = useState([
    {
      title: "Geetanjali SEE Cup- 2081 (One Day Knockout Futsal Tournament)",
      location: "Riverside Futsal",
      time: "8:30 am",
      link: "/see-futsal-cup",
      date: "2081/02/25",
      image: SEECupFutsaltournamaent,
      id: 1,
    },
    {
      title: "SEE Basket Ball Competition 2081 End",
      location: "GESS School/College",
      time: "8 am",
      link: "/see-basketball-2081-ends",
      date: "2081/02/11",
      image: SEEBasketBallImg,
      id: 2,
    },
    {
      title: "SEE Basket Ball Competition 2081 Starts",
      location: "GESS School/College",
      time: "8 am",
      link: "https://bidharthi.com/content/2922?fbclid=IwZXh0bgNhZW0CMTEAAR2L1-u5au0H89TPEO9DIUQlro2SX7VTxzDkpSEavbGllkWov4aOaZFzyyY_aem_ZmFrZWR1bW15MTZieXRlcw",
      date: "2081/02/09",
      image: SEEBasketBall,
      id: 3,
    },
    {
      title: "Price Distribution Day",
      location: "School Premises",
      time: "9 am",
      date: "2080/11/19",
      image: PrizeDistribution,
      id: 4,
    },
    {
      title: "School Picnic to Switzerland Park",
      location: "Switzerland Park, Kathmandu",
      time: "9 am",
      date: "2080/11/04",
      image: EventSwizerland,
      id: 5,
    },
    {
      title: "Volley Ball Game",
      location: "Gorkha, Dhading, Nuwakot",
      time: "9 am",
      date: "2080/06/27",
      image: Event1,
      id: 6,
    },
    {
      title: "Candidate Election",
      location: "GESS School/College",
      time: "9:30 am",
      link: "/candidate-election",
      date: "2081/02/14",
      image: CandidateElection,
      id: 7,
    },
  ]);

  return (
    <section class="container news-event-section my-125">
      <div>
        <h4
          class="title text-center text-danger mb-3"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {header}
        </h4>
      </div>

      <div className="event-card">
        <ul className="row">
          {events.map((eventsList) => (
            <div key={eventsList.id} className="col-12 col-md-6 col-lg-4 py-2">
              <li>
                <div className="card">
                  <div className="card-img">
                    <img src={eventsList.image} alt="" />
                  </div>
                  <div className="card-body">
                    <div className="card-title">
                      <h4>{eventsList.title}</h4>
                    </div>
                    <div className="card-content d-flex flex-column  justify-content-between text-muted">
                      <p>{eventsList.location}</p>
                      <div className="d-flex justify-content-between">
                        <p>Starts at: {eventsList.time}</p>
                        <p>Date: {eventsList.date}</p>
                      </div>
                      <Link to={eventsList.link} target="_blank">
                        <button className="btn btn-sm btn-primary">
                          View Detail
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Events;
