import React from "react";

const CommonBanner = ({ title }) => {
  return (
    <div className="common-banner">
      <h1 className="common-banner-title">{title}</h1>
    </div>
  );
};

export default CommonBanner;
