import { useState } from "react";
import PrincipalImage from "../Images/principal.jpg";

const SchoolMessage = () => {
  const [isExpand, setIsExpand] = useState(false);
  const readMoreBtn = () => {
    setIsExpand(!isExpand);
  };
  const [message] = useState([
    {
      principalName: "Mr. Bed Prasad Panta",
      principalImage: PrincipalImage,
      title: "Message from School Coordinator",
      subtitle: "Dear students and parents",
      // greeting: "very warm greetings !",
      messagePara1:
        "Welcome to Geetanjali English Secondary School. Geetanjali always focuses on managing good learning environment for every learner. We encourage, motivate, inspire, embolden and support to all students for their individual, academic and professional career. Here, we value each student and nurture them providing various opportunities along with different CCA and ECA programmes. Beside education, we focus on different trainings, seminars, subject wise exhibition, motivational, inspirational and career guidance. We encourage students to be accountable, assume responsibility and treat others with equal respect. Our aim is to equip the students with knowledge, curiosity, courage, creativity, confidence, competence to emerge for holistic development with sense of understanding and realization.",

      messagePara2:
        "Primarily, Geetanjali is to promote positive character traits and outburst creativity, talents and innovative thinking among the students. We focus on learning not merely the content of different subjects but also the process of updating in an ever changing world. We are striving a lot for every student’s ultimate success and personal life. Geetanjali is always caressing for every students to make the strong foundation for their better future and career to be more courageous, confident, disciplined, responsible and loyal. We empower students to be lifelong learners except only for academic excellence.",

      messagePara3:
        "Our motto, ‘Leading to the Innovative World’ is our guiding force and we remain committed to our endeavour.",

      messagePara4:
        "Finally, Geetanjali is a place of excellent and dynamic learning environment, committed and experienced faculties which enables Geetanjali a centre of excellence.",

      messagePara5:
        "I hearby invite you to be a part of Geetanjali and be sure you will acquire a great dynamism and positivity.",
    },
  ]);

  return (
    <section>
      <div className="container my-5">
        <div>
          {message.map((messages) => (
            <>
              <div className="row principal-message mb-4">
                <div className="col">
                  <h4 className="fw-bold">{messages.title}</h4>
                  <p className="m-0 text-muted">{messages.subtitle}</p>
                  <p className="text-muted">{messages.greeting}</p>
                </div>
              </div>

              <div className="content">
                <p className="text-justify">{messages.messagePara1}</p>
                <p className="text-justify">{messages.messagePara2}</p>
                <p className="text-justify">{messages.messagePara3}</p>
                <p className="text-justify">{messages.messagePara4}</p>
                <p className="text-justify">{messages.messagePara5}</p>

                <p className="fw-bold">School Coordinator</p>
                <p className="fw-bold">Mr. Shamir KC</p>
              </div>
            </>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SchoolMessage;
