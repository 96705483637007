import { useState } from "react";
import { Table } from "react-bootstrap";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";

const CollegeMessage = () => {
  const [isExpand, setIsExpand] = useState(false);
  const readMoreBtn = () => {
    setIsExpand(!isExpand);
  };
  const [message] = useState([
    {
      title: "Dear Students,",

      messagePara1:
        "As you begin the journey of higher education, it is quite important to internalize the importance of pursuing your +2 education, especially in today’s global scenario. Your +2 course lays the foundation for your future academic and professional career.",

      messagePara2:
        "In the global context, +2 education provides you with maximum knowledge and skills that are highly valued worldwide. It equips you with critical thinking abilities, problem solving skills, and a broader understanding of various subjects, preparing you to cope with the challenges of a competitive and interconnected world.",
    },
  ]);

  const [doContent, setDoContent] = useState(true);
  const [dontContent, setDontContent] = useState(false);

  const doContentBtn = () => {
    setDoContent(true);
    setDontContent(false);
  };

  const dontContentBtn = () => {
    setDoContent(false);
    setDontContent(true);
  };

  const [missionContent, setMissionContent] = useState(true);
  const [offerContent, setOfferContent] = useState(false);
  const [featureContent, setFeatureContent] = useState(false);

  const missionBtn = () => {
    setMissionContent(true);
    setOfferContent(false);
    setFeatureContent(false);
  };
  const offerBtn = () => {
    setMissionContent(false);
    setOfferContent(true);
    setFeatureContent(false);
  };
  const featureBtn = () => {
    setMissionContent(false);
    setOfferContent(false);
    setFeatureContent(true);
  };

  return (
    <section>
      <div className="container my-5">
        <div>
          {message.map((messages) => (
            <>
              <div className="row">
                <div className="">
                  <p className="fw-bold">
                    +2 Management is a two-year course designed to set up a
                    foundation on management studies.
                  </p>
                  <p>
                    The state-of-the-art facility in management stream at GESS
                    College equips the students with managerial skills aiming to
                    fulfill the needs of the expanding world of business,
                    corporate house, banking, and financial institutions. It
                    prepares students to participate in the global economy.
                  </p>
                </div>
                <div className="col">
                  <h4 className="fw-bold">{messages.title}</h4>
                </div>
              </div>
              <div className="">
                <p className="text-justify">{messages.messagePara1}</p>
                <p className="text-justify">{messages.messagePara2}</p>
                <p>
                  As you delve into your +2 education, remember to adhere to
                  certain dos and don’ts that you must follow:
                </p>

                <div>
                  <div className="d-flex mb-3 gap-2">
                    <button
                      // className="btn btn-sm btn-success d-flex align-items-center"
                      className={`btn btn-sm ${
                        doContent ? "btn-success" : "btn-outline-success"
                      }`}
                      onClick={doContentBtn}
                    >
                      <FaRegCircleCheck className="me-1" />
                      Things to do
                    </button>
                    <button
                      // className="btn btn-sm btn-outline-danger d-flex align-items-center"
                      className={`btn btn-sm ${
                        dontContent ? "btn-danger" : "btn-outline-danger"
                      }`}
                      onClick={dontContentBtn}
                    >
                      <RxCrossCircled className="me-1" />
                      Things not to do
                    </button>
                  </div>

                  {doContent && (
                    <ul className="do-content">
                      <li>
                        Attend classes regularly and actively participate in
                        discussions.
                      </li>
                      <li>
                        Manage your time effectively to balance your academics
                        and extracurricular activities.
                      </li>
                      <li>
                        Get help from your teachers whenever you face
                        difficulties.
                      </li>
                      <li>Update current affairs and global trends.</li>
                      <li>
                        Utilize resources like libraries and online platforms
                        for additional information.
                      </li>
                    </ul>
                  )}

                  {dontContent && (
                    <ul className="dont-content">
                      <li>Put off assignments and study tasks.</li>
                      <li>
                        Engage in academic dishonesty like cheating and
                        plagiarism.
                      </li>
                      <li>
                        Disregard the impotance of self-care and mental
                        well-being.
                      </li>
                      <li>
                        Neglect extracurricular activities and personal
                        development opportunities.
                      </li>
                    </ul>
                  )}

                  <p>
                    By strictly following to these dos and don’ts, you will
                    maximize the benefits of your +2 education and lay a solid
                    foundation for your future endeavors. Best wishes for your
                    academic journey ahead.
                  </p>
                  <p className="fw-bold m-0">Regards</p>
                  <p className="fw-bold m-0">Co-Ordinator</p>
                  <p className="fw-bold m-0">Base Dev Adhikari</p>
                </div>
              </div>
            </>
          ))}
        </div>
        {/* message ends */}

        <div className="my-5">
          <h4 className="title">Major Attractions</h4>
          <ul>
            <li>
              We own a well set up Computer lab with high-speed Internet
              facilities.
            </li>
            <li>
              We frequently organize seminars &amp; workshops by
              national/international experts, businesspersons, bankers,
              academicians, and professionals.
            </li>
            <li>
              We create a collaborative and conducive research environment
              within GESS communities.
            </li>
            <li>We focus on presentation and case study on relevant topics.</li>
            <li>GESS stresses practical and theoretical knowledge.</li>
            <li>
              We have an attractive Hotel Management Lab as another priority
              amenity.
            </li>
            <li>
              We organize regular ECA/CCA activities and theme-based orientation
              sessions.
            </li>
          </ul>

          <h4 className="title mt-5">Course We Offer</h4>
          <Table className="table" resposive striped bordered hover>
            <thead>
              <tr>
                <th>Compulsory Subjects</th>
                <th>Optional Subjects</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>English</td>
                <td>Computer Science</td>
              </tr>
              <tr>
                <td>Nepali</td>
                <td>Hotel Mangement</td>
              </tr>
              <tr>
                <td>Mathematics/Social Studies</td>
                <td>Business Studies</td>
              </tr>
              <tr>
                <td></td>
                <td>Tourism and Mountaineering</td>
              </tr>
              <tr>
                <td></td>
                <td>Accountancy</td>
              </tr>
              <tr>
                <td></td>
                <td>Economics</td>
              </tr>
              <tr>
                <td></td>
                <td>Major English</td>
              </tr>
              <tr>
                <td></td>
                <td>Major Nepali</td>
              </tr>
              <tr>
                <td></td>
                <td>Mass Communication</td>
              </tr>
              <tr>
                <td></td>
                <td>Sociology</td>
              </tr>
              <tr>
                <td></td>
                <td>Music-vocal & instrumental - singing</td>
              </tr>
              <tr>
                <td></td>
                <td>Rural Development</td>
              </tr>
              <tr>
                <td></td>
                <td>Education</td>
              </tr>
              <tr>
                <td></td>
                <td>Law</td>
              </tr>
            </tbody>
          </Table>

          <h4 className="title mt-5">Mission and Vision</h4>
          <div className="d-flex mb-3 gap-2">
            <button
              onClick={missionBtn}
              className={`btn btn-sm ${
                missionContent ? "btn-success" : "btn-outline-success"
              }`}
            >
              Mission & Vision
            </button>
            <button
              onClick={offerBtn}
              className={`btn btn-sm ${
                offerContent ? "btn-success" : "btn-outline-success"
              }`}
            >
              GESS College Offers
            </button>
            <button
              onClick={featureBtn}
              className={`btn btn-sm ${
                featureContent ? "btn-success" : "btn-outline-success"
              }`}
            >
              Salient Features
            </button>
          </div>
          {missionContent && (
            <div className="mission-content">
              <ol>
                <li>
                  Assurance of brand with excellence in academic activities
                  imparting the knowledge.
                </li>
                <li>Promote and foster intellectual entity.</li>
                <li>Develop the sense of social responsibility.</li>
                <li>
                  Prepare skilled and innovative manpower to face the
                  cosmopolitan world.
                </li>
                <li>Inculcate professionalism in an individual.</li>
                <li>
                  Develop ethical, moral, cultural, educational and social
                  values of humanities.
                </li>
                <li>Ensure brighter future for all.</li>
                <li>Assurance of academic and co-curricular excellence.</li>
                <li>
                  Prepare individual to face the challenges and turn them into
                  opportunities.
                </li>
                <li>Nurture potentials of the individuals.</li>
                <li>
                  Equip learners with good morality, knowledge, skills and need
                  based education.
                </li>
                <li>
                  Inspire students to be innovative, independent, responsible
                  global citizen.
                </li>
              </ol>
            </div>
          )}

          {offerContent && (
            <div className="offer-content">
              <ol>
                <li>
                  Sports
                  <ul>
                    <li>Basketball</li>
                    <li>Volleyball</li>
                    <li>Futsal</li>
                    <li>Badminton</li>
                    <li>Table Tennis</li>
                  </ul>
                </li>
                <li>
                  Cafeteria with delicious food items
                  <ul>
                    <li>Neat and clean spacious cafeteria</li>
                    <li>Varieties of food items</li>
                    <li>Comfortable and cozy table setting</li>
                  </ul>
                </li>
                <li>
                  Field Trip and Educational Excursion
                  <ul>
                    <li>Site Visit</li>
                    <li>City Tour</li>
                    <li>Hiking to neighboring hill of Ktm valley</li>
                    <li>Swimming</li>
                    <li>Rafting</li>
                    <li>Educational Excursion</li>
                    <li>Home Stay Visit</li>
                    <li>Night Stay Out and Camping</li>
                  </ul>
                </li>
                <li>Cultural Programs</li>
                <li>Transportation Facility</li>
              </ol>
            </div>
          )}

          {featureContent && (
            <div className="feature-content">
              <ol>
                <li>
                  Education with moral values, independence, discipline and
                  “Sanskar.”
                </li>
                <li>
                  Fostering critical thinking, research and discovery based
                  professional development.
                </li>
                <li>
                  Youth centered diverse counseling to inspire young people for
                  quality life.
                </li>
                <li>
                  Organizing seminars, workshops, and empowerment programs.
                </li>
                <li>Specious premises, rooms, natural greenery and scenery.</li>
                <li>Highly qualified, dedicated and experienced faculties.</li>
                <li>
                  Well-equipped hotel management lab, computer lab, music lab,
                  science lab.
                </li>
                <li>
                  Varieties of books in the library to foster inter personal
                  knowledge of the students.
                </li>
                <li>Large scholarship scheme available.</li>
                <li>Priority to ECA and CCA programs.</li>
              </ol>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CollegeMessage;
