import BachelorsMessage from "../Components/BachelorsMessage";
import Banner from "../Components/Banner";
const Bachelors = () => {
  return (
    <>
      <Banner title="Bachelors Level" />
      <BachelorsMessage />
    </>
  );
};

export default Bachelors;
