import one from "../../Images/Events/CandidateElection/1.jpg";
import two from "../../Images/Events/CandidateElection/2.jpg";
import three from "../../Images/Events/CandidateElection/3.jpg";
import four from "../../Images/Events/CandidateElection/4.jpg";
import five from "../../Images/Events/CandidateElection/5.jpg";
import six from "../../Images/Events/CandidateElection/6.jpg";
import seven from "../../Images/Events/CandidateElection/7.jpg";
import eight from "../../Images/Events/CandidateElection/8.jpg";
import nine from "../../Images/Events/CandidateElection/9.jpg";

export const CandidateElectionGallery = [
  {
    id: 1,
    image: one,
  },
  {
    id: 2,
    image: two,
  },
  {
    id: 3,
    image: three,
  },
  {
    id: 4,
    image: four,
  },
  {
    id: 5,
    image: five,
  },
  {
    id: 6,
    image: six,
  },
  {
    id: 7,
    image: seven,
  },
  {
    id: 8,
    image: eight,
  },
  {
    id: 9,
    image: nine,
  },
];
