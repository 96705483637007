import { React, useEffect } from "react";
import AOS from "aos";

const Banner = ({ title }) => {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="banner">
      <h1 data-aos="fade-up" data-aos-duration="1000">
        {title}
      </h1>
    </div>
  );
};

export default Banner;
