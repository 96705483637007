import React from "react";
import Logo from "../Images/logo.png";
import { Link } from "react-router-dom";

function Footer() {
  const current = new Date();
  const currentYear = `${current.getFullYear()}`;

  return (
    <footer class="footer mt-5 py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <img src={Logo} alt="" />
            <div class="contact-detail">
              <p class="address">Khusibun-TownPlanning, Nayabazaar, Kathmandu, Nepal</p>
              <p class="contact">014959440, 014951494</p>
              <p class="email">geetanjalischoolcollege@gmail.com</p>
            </div>
            <div class="social-media">
              <ul>
                <Link
                  to="https://www.facebook.com/geetanjalischoolcollege"
                  target="_blank"
                >
                  <li>
                    <i class="fa-brands fa-facebook"></i>
                  </li>
                </Link>
                <Link
                  to="https://www.instagram.com/geetanjalischoolcollege/"
                  target="_blank"
                >
                  <li>
                    <i class="fa-brands fa-square-instagram"></i>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          <div class="col-md-2 col-6 footer-column">
            <h4>About</h4>
            <ul>
              <Link to="/about">
                <li>
                  <a href="/about">About us</a>
                </li>
              </Link>
              <Link to="/infrastructure">
                <li>infrastructure & Facilities</li>
              </Link>
              <Link to="/about">
                <li>Our Team</li>
              </Link>
            </ul>
          </div>
          <div class="col-md-2 col-6 footer-column">
            <h4>Academics</h4>
            <ul>
              <Link to="/pre-primary">
                <li>Pre-primary</li>
              </Link>
              <Link to="/school">
                <li>Grade 1-10</li>
              </Link>
              <Link to="/college">
                <li>Plus 2</li>
              </Link>
              <Link to="/bachelors">
                <li>Bachelors</li>
              </Link>
              <Link to="/admissino-form" target="_blank">
                <li>
                  <a href="/*">Admission</a>
                </li>
              </Link>
            </ul>
          </div>
          <div class="col-md-2 col-6 footer-column">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="/*">Rules and Regulations</a>
              </li>
              <li>
                <Link to="/calendar-2080">Calendar</Link>
              </li>
              <li>
                <Link to="/geetanjali-voice-2079">Geetanjali Voice</Link>
              </li>

              <li>
                <a href="/*">Extra & Co-Curriculars</a>
              </li>
            </ul>
          </div>
          <div class="col-md-2 col-6 footer-column">
            <h4>Contact</h4>
            <ul>
              <li>
                <a href="/conatct">Contact us</a>
              </li>
              <Link to="/admission-form" target="_blank">
                <li>Apply Online</li>
              </Link>
            </ul>
            <h4>Downloads</h4>
            <ul>
              <li>
                <Link to="/geetanjali-voice-2079">Geetanjali Voice</Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="copyright d-flex justify-content-between align-items-center flex-column flex-md-row">
          <p className="m-0">&copy; Geetanjali School/College {currentYear}</p>
          <p className="m-0">Developed by: IT Sansaar</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
