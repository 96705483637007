
import two from "../../Images/fustal/2.JPG";
import three from "../../Images/fustal/3.JPG";
import four from "../../Images/fustal/4.JPG";
import five from "../../Images/fustal/5.JPG";
import six from "../../Images/fustal/6.JPG";
import seven from "../../Images/fustal/7.JPG";
import one from "../../Images/fustal/1.JPG";
import eight from "../../Images/fustal/8.JPG";
import nine from "../../Images/fustal/9.JPG";
import ten from "../../Images/fustal/10.JPG";
import eleven from "../../Images/fustal/11.JPG";
import twelve from "../../Images/fustal/12.JPG";
import thirteen from "../../Images/fustal/13.JPG";
import fourteen from "../../Images/fustal/14.JPG";
import fifteen from "../../Images/fustal/15.JPG";
import sixteen from "../../Images/fustal/16.JPG";
import seventeen from "../../Images/fustal/17.JPG";
import eighteen from "../../Images/fustal/18.JPG";
import nineteen from "../../Images/fustal/19.JPG";
import twenty from "../../Images/fustal/20.JPG";
import twentyOne from "../../Images/fustal/21.JPG";
import twentyTwo from "../../Images/fustal/22.JPG";
import twentyThree from "../../Images/fustal/23.JPG";
import twentyFour from "../../Images/fustal/24.JPG";
import twentyFive from "../../Images/fustal/25.JPG";
import twentySix from "../../Images/fustal/26.JPG";
import twentySeven from "../../Images/fustal/27.JPG";
import twentyEight from "../../Images/fustal/28.JPG";
import twentyNine from "../../Images/fustal/29.JPG";
import thirty from "../../Images/fustal/30.JPG";
import thirtyOne from "../../Images/fustal/31.JPG";
import thirtyTwo from "../../Images/fustal/32.JPG";
import thirtyThree from "../../Images/fustal/33.JPG";
import thirtyFour from "../../Images/fustal/34.JPG";
import thirtyFive from "../../Images/fustal/35.JPG";
import thirtySix from "../../Images/fustal/36.JPG";
import thirtySeven from "../../Images/fustal/37.JPG";
import thirtyEight from "../../Images/fustal/38.JPG";
import thirtyNine from "../../Images/fustal/39.JPG";
import fourty from "../../Images/fustal/40.JPG";
import fourtyOne from "../../Images/fustal/41.JPG";
import fourtyTwo from "../../Images/fustal/42.JPG";
import fourtyThree from "../../Images/fustal/43.JPG";





export const SEEFutsalImages = [
 
  

  {
    id: 1,
    src: one,
  },
  {
    id: 2,
    src: two,
  },
  {
    id: 3,
    src: three,
  },
  {
    id: 4,
    src: four,
  },
  {
    id: 5,
    src: five,
  },
  {
    id: 6,
    src: six,
  },
  {
    id: 7,
    src: seven,
  },
  
  {
    id: 8,
    src: eight,
  },
  {
    id: 9,
    src: nine,
  },
  {
    id: 10,
    src: ten,
  },
  {
    id: 11,
    src: eleven,
  },
  {
    id: 12,
    src: twelve,
  },
  {
    id: 13,
    src: thirteen,
  },
  {
    id: 14,
    src: fourteen,
  },
  
  {
    id: 15,
    src: fifteen,
  },
  {
    id: 16,
    src: sixteen,
  },
  {
    id: 17,
    src: seventeen,
  },
  {
    id: 18,
    src: eighteen,
  },
  {
    id: 19,
    src: nineteen,
  },
  {
    id: 20,
    src: twenty,
  },
  {
    id: 21,
    src: twentyOne,
  },
  {
    id: 22,
    src: twentyTwo,
  },
  {
    id: 23,
    src: twentyThree,
  },
  {
    id: 24,
    src: twentyFour,
  },
  {
    id: 25,
    src: twentyFive,
  },
  {
    id: 26,
    src: twentySix,
  },
  {
    id: 27,
    src: twentySeven,
  },
  {
    id: 28,
    src: twentyEight,
  },
  {
    id: 29,
    src: twentyNine,
  },
  {
    id: 30,
    src: thirty,
  },
  {
    id: 31,
    src: thirtyOne,
  },
  {
    id: 32,
    src: thirtyTwo,
  },
  {
    id: 33,
    src: thirtyThree,
  },
  {
    id: 34,
    src: thirtyFour,
  },
  {
    id: 35,
    src: thirtyFive,
  },
  {
    id: 36,
    src: thirtySix,
  },
  {
    id: 37,
    src: thirtySeven,
  },
  {
    id: 38,
    src: thirtyEight,
  },
  {
    id: 39,
    src: thirtyNine,
  },
  {
    id: 40,
    src: fourty,
  },
  {
    id: 41,
    src: fourtyOne,
  },
  {
    id: 42,
    src: fourtyTwo,
  },
  {
    id: 43,
    src: fourtyThree,
  },

];
