import React from "react";

const Infrastructure = () => {
  return (
    <div className="container my-5">
      <h4>Infrastructure and Facilities</h4>
      <p>
        Every component of the school/college contributes to creating a studious
        environment for effective teaching and learning activities, so GESS
        administration focuses on:
      </p>

      <ol>
        <li>
          Classrooms: Spacious rooms equipped with desks, chairs, greenboards.
        </li>
        <li>
          Library: Providing access to books, journals, computers, and study
          space.
        </li>
        <li>
          Laboratories: Different laboratories are set up for learning subjects
          like science, computer science, music, and hotel management.
        </li>
        <li>
          Sports Facilities: Playgrounds, sports fields, basketball court,
          volleyball court, badminton court and table tennis boards are
          available.
        </li>
        <li>
          Cafeteria: Offering nutritious food and comfortable and cozy spacious
          space.
        </li>
        <li>
          Administrative Offices: Separate offices are located to manage
          enrollment, finances, and other administrative tasks.
        </li>
        <li>
          Health Care Station: Providing basic medical care and first aid
          facility.
        </li>
        <li>
          Counseling Services: Offering support for students’ emotional and
          psychological needs.
        </li>
        <li>
          Outdoor Space: Garden with greenery, Trees providing shadow, rest
          station and waiting room.
        </li>
        <li>
          Security Measures: We provide gates, fencing walls, surveillance
          cameras and security personnel to ensure safety.
        </li>
        <li>
          Technology Infrastructure: We have free Wi-Fi zone, computers,
          internet facilities, audio visual devices for teaching learning
          activities.
        </li>
        <li>
          Auditorium Hall: GESS owns a hall for seminars, ECA and CCA programs.
        </li>
      </ol>
    </div>
  );
};

export default Infrastructure;
