import React, { useState } from "react";
import SaraswatiPuja from "../Images/Article/saraswati-puja.jpeg";
import studentsJumping from "../Images/stunde-jumping.jpg";
import { Link } from "react-router-dom";

const Article = () => {
  const title = "Articles and News";
  const [artilce, setArticle] = useState([
    {
      title: "A Glimpse Of Geetanjali",
      description:
        "Geetanjali school/college is named after the holy scriptures by Rabindra Nath Tagore- a noble prize winner literary piece “Geetanjali” hoping to brand its name and fame.",
      link: "/best-management-college-in-kathmandu-valley",
      image: studentsJumping,
      author: "Bed Prasad Panta",
      date: "2 Jestha, 2081 B.S",
    },
    {
      title: "Saraswati Puja",
      description:
        "On very auspicious occasion of Basant Panchami, devotees across Nepal and abroad celebrated Saraswati Puja with immense fervor. The early morning of the 2nd Falgun 2080 witnessed a gathering of students, scholars, teachers, guardians, and worshippers at Saraswati Temples nationwide, honoring the revered Goddess of Knowledge. Similar event happened in the premises of Geetanjali College, one of the vibrant educational institutions located in Khushibu, Town Planning, Nayabazar, Kathmandu valley.",
      link: "/saraswati-puja",
      image: SaraswatiPuja,
      author: "Bed Prasad Panta",
      date: "2 Falgun, 2080 B.S",
    },
  ]);

  return (
    <section class="container artilce-section my-125">
      <div>
        <div>
          <h4
            class="title text-center text-danger mb-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {title}
          </h4>
        </div>
        <div className="article-card">
          <ul className="row">
            {artilce.map((artilceList) => (
              <div key={artilceList.id} className="col-12 col-md-4">
                <li>
                  <div className="card">
                    <div className="card-img">
                      <img src={artilceList.image} alt="" />
                    </div>
                    <div className="card-body">
                      <div className="card-title">
                        <h4>{artilceList.title}</h4>
                      </div>
                      <div className="card-content d-flex flex-column  justify-content-between text-muted">
                        <p className="artilce-description">
                          {artilceList.description}
                        </p>
                        <div className="d-flex flex-column justify-content-between">
                          <p className="text-muted m-0">
                            Author: {artilceList.author}
                          </p>
                          <p className="text-muted">Date: {artilceList.date}</p>
                          <Link to={artilceList.link}>
                            <button className="btn btn-sm btn-primary">
                              Read More
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Article;
