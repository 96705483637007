import { Accordion, Container, Row, Col } from "react-bootstrap";
import FaqImage from "../Images/school.jpeg";

const Faq = () => {
  return (
    <Container className="my-5 faq-container">
      <h4
        className="title mb-4 text-center text-danger pt-5"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Frequently Asked Questions
      </h4>
      <Row>
        <Col xs={12} md={6}>
          <img src={FaqImage} alt="frequently asked questions" />
        </Col>
        <Col>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Do you accept new admission?</Accordion.Header>
              <Accordion.Body>Yes, we do.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Where is the college located?</Accordion.Header>
              <Accordion.Body>
                We are located at Nayabazaar, Kathmandu, Nepal.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>How much +2 program cost?</Accordion.Header>
              <Accordion.Body>
                Cost varies according to the subject you take.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Is there a Science stream?</Accordion.Header>
              <Accordion.Body>
                No, we dont have but we do have computer science.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Is there any scholarship available?
              </Accordion.Header>
              <Accordion.Body>
                Yes, we offer a great deal of scholarship.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Is there dalit cota available?
              </Accordion.Header>
              <Accordion.Body>Yes, there is as per the rule.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Is there any provision of entrance test?
              </Accordion.Header>
              <Accordion.Body>
                Yes, there is. You must complusorily attend the entrace test.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                How much GPA is required for admission in this college?
              </Accordion.Header>
              <Accordion.Body>
                You must atlease score minimum GPA as the NEB has set i.e 1.6
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                How can we get information about the college?
              </Accordion.Header>
              <Accordion.Body>You can call or message us.</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Faq;
