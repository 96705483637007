const LocationMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d553.3804517789665!2d85.30276782481258!3d27.722092506863966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18ef540bc0df%3A0xa1bb90a4ec055185!2sGeetanjali%20College!5e0!3m2!1sen!2snp!4v1697351066289!5m2!1sen!2snp"
        // width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        style={{ width: "100%" }}
      ></iframe>
    </div>
  );
};

export default LocationMap;
