import React from "react";
import pdf from "../../Components/ExamRoutine/Grade-12-routine.pdf";

const Grade12Routine = () => {
  return (
    <div className="my-5 pdf-view">
      <h4 className="text-center">Grade 12 Board Exam Routine</h4>
      <iframe className="py-5" src={pdf} />
    </div>
  );
};

export default Grade12Routine;
