import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Banner from "../Components/Banner";
import PrePrimaryBanner from "../Images/pre-primary.JPG";
import AOS from "aos";

const Preprimary = () => {
  const title = "Importance And Essence Of Pre-Primary Education";
  const title2 = "Introduction To Pre-Primary Level";

  useEffect(() => {
    AOS.init();
  });

  return (
    <>
      <div className="banner pre-primary-banner">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          {title}
        </h1>
      </div>

      <Container className="py-5">
        <h3 className="text-danger" data-aos="fade-down">
          {title}
        </h3>
        <div className="content">
          <p className="text-justify">
            Pre-primary education in Geetanjali is uniquely designed to furnish
            toddlers with a beautiful career. This age is very potential for all
            students to set up a baseline for career building. Our experience
            has shown us that little children grow and develop quickly from the
            age of zero to five. So all the stakeholders – parents, teachers,
            educators, government, and policymakers must think of utilizing
            these potential years constructively. We must be sure and confident
            that the little children are busy in playful learning. We have to
            prepare the toddlers to attain ultimate success through playful
            learning.
          </p>
          <p className="text-justify">
            Geetanjali administration has realized the value of learning in the
            early years of life. It may be simply through games, funs, signs,
            colors, songs, and so on. We know that the learning supports lay a
            foundation ground in the academic and social sector.
          </p>
          <p className="text-justify">
            In pre-primary, for example, students will learn how to count.
            Counting enables them to learn more complex concepts like
            multiplication and calculus later in their lives. Students with weak
            foundations often get left behind or have to spend much more time
            and energy as they spend time playing.
          </p>
          <p className="text-justify">
            In terms of academic learning, children are exposed to two of the
            most important way of learning in pre-primary, namely numbers and
            literature.
          </p>
          <p className="text-justify">
            Similarly, language and literacy skills affect students reading,
            writing, and communication skill. And this extends far beyond
            learning in a Nepali or English class because for almost every
            subject that students learn they must be able to read, process, and
            ask questions in their language of instruction. In fact, reading and
            clear communication are critical to achieving success in the modern
            world.
          </p>
          <p className="text-justify">
            Pre-primary schooling also has a tremendous impact on students’
            social development. It also plays a vital role in the building of
            their social-emotional skills. Being and learning in new and
            unfamiliar environments as well as meeting, learning, and
            interacting with fellow students and teachers prepare students to
            socialize. Primary education enables toddlers to learn how to manage
            their emotions, cooperate and share exercise self-control and start
            to be independent.
          </p>
          <p className="text-justify">
            In fact, researchers around the world agree that effective learning
            in the early years can dramatically affect one success in school
            going forward.
          </p>
          <p className="text-justify">
            If we peep into a pre-primary classroom and see students and
            teachers playing, painting, enjoying themselves, or learning in ways
            that seem quite modern to us and you find yourself wondering whether
            schooling in pre-primary really is important, remind yourself of
            life-changing and long-lasting benefits you just read about.
          </p>
        </div>
      </Container>

      <div className="image-banner">
        <img src={PrePrimaryBanner} alt="pre-primary" className="w-100" />
      </div>

      <Container className="py-5">
        <h3 className="mt-5 text-danger" data-aos="fade-down">
          {title2}
        </h3>
        <p className="text-justify">
          Geetanjali Pre-School is uniquely established to explore self
          dedication, independent learning in the kids with the help of their
          tutors and the school environment. Geetanjali focuses to develop
          practical life skill in the students i.e. self-care and home care
          skills which will be extra ornamental achievement in their lives.
        </p>
        <p className="text-justify">
          Geetanjali adopts sensory development activities adjusting kids with
          senses, fine motor skills and problem solving capacity. We know fun
          games and other such activities help children hone their best and
          gross motor skills. Beside these, the school works to build up
          language and communicating skill in social setting through stories,
          songs, games and funs. We encourage students think critically and set
          mathematical concept via counting, matching and sorting.
        </p>
        <p className="text-justify">
          Exploring nature is the common base in the Montessori world so
          Geetanjali inspires and creates environment to learn about weather,
          seasons, plants and animals through field visit and innovative play
          materials. We try to set heads on experience creating flexibility on
          study.
        </p>
        <p className="text-justify">
          Our classrooms and programs are designed to impart better learning
          opportunities and to teach independent thinking skills to frame better
          career. We are devoted and dedicated to offer extra enhancement and
          develop a child into a man of above average height. Thank you. Wish
          you all the best. Pincipal
        </p>
      </Container>
    </>
  );
};

export default Preprimary;
