
import React from "react";

const Notice = () => {
  return (
    <div className="container my-5">
      <h4 className="title">Important Notice</h4>
      <p>
        This is to inform all concerned Grade 11 students about the upcoming
        academic schedule and they are asked to strongly follow it. If anyone
        fails to follow will be responsible himself or herself for any kind of
        distortion.
      </p>
      <ol>
        <li className="fw-bold">
          Pre-board Examination
          <ul>
            <li>Pre-board Examination form fill Date: 6 to 9 Baishakh 2081</li>
            <li>Pre-board Examination Date: 12 to 18 Baishakh 2081</li>
            <li>Pre-board Examination Result Date: 28 Baisakh 2081</li>
          </ul>
        </li>
        <li className="fw-bold">
          Internal Evaluation Examination
          <ul>
            <li> Internal Evaluation Examination Date: 8 to 11 Jestha 2081</li>
          </ul>
        </li>
        <li className="fw-bold">
          Board Examination
          <ul>
            <li>Board Examination form fill up date: 6 to 11 Jestha 2081</li>
            <li>
              Board Examination Admit Card distribution date: 15 - 19 Jestha
              2081
            </li>
            <li>Board Examination Date: 20th - 25th Jestha 2081</li>
          </ul>
        </li>
        <li className="fw-bold">
          Board Examination Preparation break
          <ul>
            <li>
              Board Examination preparation break begin from 13 to 19 Jestha
              2081
            </li>
          </ul>
        </li>
        <li className="fw-bold">
          Board Examination
          <ul>
            <li>Board Examination dates: 20 Jestha to 25 Jestha 2081</li>
            <li>Board Examination Result date: 5 Asar 2081</li>
          </ul>
        </li>
        <li className="fw-bold">
          Admission in Grade 12
          <ul>
            <li>Commercing from 5 Asar 2081</li>
            <li>Grade 12 new classes begin from 9 Asar 2081</li>
          </ul>
        </li>
        <li className="fw-bold">
          Exam Fees
          <ul>
            <li>Pre-board Exam fee: Rs 1000/- per stundet</li>
            <li>Board Exam fee: Rs 800/- per stundent</li>
            <li>Internal Evaluation fee: Rs. 100/- per subject</li>
          </ul>
        </li>
      </ol>
      <em className="text-muted">
        For any further queries or assistance regarding the examinations, please
        contact the school administration <br /> Thank you <br /> Principal
      </em>
    </div>
  );
};

export default Notice;
