import React from "react";
import saraswatiPuja from "../../Images/Article/saraswati-puja-gess.jpeg";

const SaraswatiPuja = () => {
  const shareUrl = "https://www.geetanjalischoolcollege.edu.np/saraswati-puja";
  const ogTitle =
    "Geetanjali Celebrates Saraswati Puja with Grandeur on Basant Panchami";
  const ogDescription =
    " On very auspicious occasion of Basant Panchami, devotees across Nepal and abroad celebrated Saraswati Puja with immense fervor.The early morning of the 2nd Falgun 2080 witnessed a gathering of students, scholars, teachers, guardians, and worshippers at Saraswati Temples nationwide, honoring the revered Goddess of Knowledge. Similar event happened in the premises of Geetanjali College, one of the vibrant educational institutions located in Khushibu, Town Planning, Nayabazar, Kathmandu valley.";
  const ogImageUrl = "../../Images/Article/saraswati-puja-gess.jpeg";
  return (
    <div className="container">
      <div className="article">
        <h1 className="article-title">
          Geetanjali Celebrates Saraswati Puja with Grandeur on Basant Panchami
        </h1>
        <span className="header-image">
          <img src={saraswatiPuja} alt="" />
          <figcaption className="text-muted fs-12">
            <em>Group photo during "Saraswati Puja"</em>
          </figcaption>
        </span>
        <div className="article-content">
          <p>
            On very auspicious occasion of Basant Panchami, devotees across
            Nepal and abroad celebrated Saraswati Puja with immense fervor. The
            early morning of the 2nd Falgun 2080 witnessed a gathering of
            students, scholars, teachers, guardians, and worshippers at
            Saraswati Temples nationwide, honoring the revered Goddess of
            Knowledge. Similar event happened in the premises of Geetanjali
            College, one of the vibrant educational institutions located in
            Khushibu, Town Planning, Nayabazar, Kathmandu valley.
          </p>
          <p>
            Saraswati Puja holds great significance, especially in the academic
            realm along with musicians, actors, sculptors, painters symbolizing
            the pursuit of wisdom and learning. Various schools and colleges
            orchestrated special functions within their premises, acknowledging
            the divine blessings of Goddess Saraswati upon education. Geetanjali
            College orchestrated a special puja, while others organized
            seminars, poetry recitation and so on emphasizing the inseparable
            connection between education and spirituality among students and
            teachers. The principal performed puja under pandit Gokarna Khanal’s
            various mantras and hymns representing the aspirations to growing
            institutional prosperity, connecting the physical world into
            spiritual feeling of academic community.
          </p>
          <p>
            Goddess Saraswati’s value spreads through out deeply into the
            academic sector, showing the importance of knowledge and
            enlightenment. Goddess Saraswati is believed to be the patroness of
            learning; her divine presence underscores the importance of
            education in society. Many believe that seeking her blessings on
            Basant Panchami ensures academic success and intellectual prowess.
          </p>
          <p>
            Furthermore, the day witnesses a significant trend of preschool
            admissions, as parents perceive it as an auspicious beginning for
            their children&#39;s educational journey. Saraswati Puja serves as a
            catalyst, igniting the aspirations of young minds and nurturing a
            culture of reverence for education. So Geetanjali pre school invites
            applications from all the potential parents. We leave no stone
            unturned to foster all round development of all the children who
            come under the Geetanjali community.
          </p>
          <p>
            As Geetanjali family and the like celebrate Saraswati Puja with
            excitement, it proves the enduring belief in the transformative
            power of knowledge, reflected in the divine persona of Goddess
            Saraswati.
          </p>

          <span>
            <p>
              Bed Prasad Panta <br />
              Principal
              <br />
              Geetanjali College
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SaraswatiPuja;
