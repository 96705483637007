import "./App.css";
import Footer from "./Components/Footer";
import Menu from "./Components/Menu";
import Home from "./Pages/Home";
import About from "./Pages/About";
import School from "./Pages/School";
import Bachelors from "./Pages/Bachelors";
import Preprimary from "./Pages/Preprimary";
import Contact from "./Pages/Contact";
import Result from "./Pages/Result";

import ErrorPage from "./Pages/ErrorPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SaraswatiPuja from "./Article/Articles/SaraswatiPuja";
import ExamRountine from "./Pages/ExamRountine";
import Gallery from "./Components/Gallery";
import Grade12ExamRoutine from "./Pages/Grade12ExamRoutine";
import College from "./Pages/College";
import AdmissionPage from "./Pages/AdmissionPage";

import { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import Infrastructure from "./Pages/Infrastructure";
import Calender from "./Components/Calendar";
import GeetanjaliVoice from "./Components/GeetanjaliVoice";
import Notice from "./Pages/Notice";
import GeetanjaliManagementCollege from "./Article/Articles/GeetanjaliManagementCollege";
import SEEBasketBall from "./Events/Events/SEEBasketBall";
import CandidateElection from "./Events/Events/CandidateElection";
import SEEFutsal from "./Events/Events/SEECupFutsaltournament";

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      scroll.scrollToTop();
    }, [pathname]);

    return null;
  }
  return (
    <>
      <ScrollToTop />
      <Menu />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/school" element={<School />} />
        <Route path="/pre-primary" element={<Preprimary />} />
        <Route path="/college" element={<College />} />
        <Route path="/bachelors" element={<Bachelors />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/result" element={<Result />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/saraswati-puja" element={<SaraswatiPuja />} />
        <Route
          path="/best-management-college-in-kathmandu-valley"
          element={<GeetanjaliManagementCollege />}
        />
        <Route path="/exam-routine" element={<ExamRountine />} />
        <Route path="/admission-form" element={<AdmissionPage />} />
        <Route path="/infrastructure" element={<Infrastructure />} />
        <Route path="/calendar-2081" element={<Calender/>} />
        <Route path="/geetanjali-voice-2079" element={<GeetanjaliVoice />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/grade-12-board-exam-routine" element={<Grade12ExamRoutine />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/see-basketball-2081-ends" element={<SEEBasketBall />} />
        <Route path="/candidate-election" element={<CandidateElection />} />
        <Route path="/see-futsal-cup" element={<SEEFutsal />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
