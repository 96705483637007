import React from "react";
import PreprimaryRoutine from "../Components/ExamRoutine/PreprimaryRountine";
import PrimaryRoutine from "../Components/ExamRoutine/PrimaryRountine";
import SecondaryLevelRoutine from "../Components/ExamRoutine/SecondaryLevelRoutine";
import SeeRoutine from "../Components/ExamRoutine/SeeRoutine";
import BLE from "../Components/ExamRoutine/BLE";
import Grade11Routine from "../Components/ExamRoutine/Grade-11-routine";

const ExamRountine = () => {
  return (
    <div className="container">
      <Grade11Routine />
    </div>
  );
};

export default ExamRountine;
