import React from "react";
import Fancybox from "./Fancybox";
import { ImagesBD } from "./ImagesDB";

const BannerGallery = () => {
  return (
    <div className="container-fluid">
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        <div className="banner-image">
          {ImagesBD.map((list, id) => (
            <div className="col-md-2 col-12 gallery-image my-3">
              <a key={list.id} data-fancybox="gallery" href={list.src}>
                <img
                  src={list.src}
                  width="200"
                  height="150"
                  alt={`${list.id}-image`}
                />
              </a>
            </div>
          ))}
        </div>
      </Fancybox>
    </div>
  );
};

export default BannerGallery;
