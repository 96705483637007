
import React from "react";
import Result11 from "../Components/Result/Result11";

const Result = () => {
  return (
    <div className="container">
      <Result11/>
    </div>
  );
};

export default Result;