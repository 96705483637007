import Fancybox from "./Fancybox";
import React from "react";
import { galleryImages } from "./GalleryDB";
import { GalleryImageDB } from "./ImagesDB";
import SEEFutsalGallery from "../Events/Events/SEEFutsalGallery";
import SEEBasketBallGallery from "../Events/Events/SEEBasketBallGallery";
const Gallery = () => {
  return (
    <>

<SEEFutsalGallery />


<SEEBasketBallGallery />

      <div className="container">
        <h1 className="mb-5">School/College Images</h1>
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          {GalleryImageDB.map((list, id) => (
            <div className="col-md-3 col-12 gallery-image my-3">
              <a key={list.id} data-fancybox="gallery" href={list.src}>
                <img
                  src={list.src}
                  width="200"
                  height="150"
                  alt={`${list.id}-image`}
                />
              </a>
            </div>
          ))}
        </Fancybox>
      </div>

      <div className="container my-5">
        <h1 className="mb-5">Some Glimpse Of Geetanjali 2080</h1>
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          {galleryImages.map((list, index) => (
            <div className="col-md-3 col-12 gallery-image my-3">
              <a key={list.id} data-fancybox="gallery" href={list.src}>
                <img
                  src={list.src}
                  width="200"
                  height="150"
                  alt={`${list.id}-image`}
                />
              </a>
            </div>
          ))}
        </Fancybox>
      </div>
    </>
  );
};

export default Gallery;
