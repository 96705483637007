import { useState } from "react";
import PrincipalImage from "../Images/principal.jpg";

const SchoolMessage = () => {
  const [isExpand, setIsExpand] = useState(false);
  const readMoreBtn = () => {
    setIsExpand(!isExpand);
  };
  const [message] = useState([
    {
      principalName: "Mr. Bed Prasad Panta",
      principalImage: PrincipalImage,
      title: "Message from Bachelor Coordinator",
      subtitle: "Dear students and parents",

      messagePara1:
        "Welcome to Geetanjali College, a temple of adequate knowledge.Converting an individual into a self-dependent citizen by the fusion of scholarly and co-scholarly activities is the motto of Geetanjali College.",

      messagePara2:
        "Our college has the history of almost one and half decades of imparting life-changing practical education out of theoritical base. It has the mission to expose the potentials of individual students and promote their excellence in every walk of life for secure future. We feel proud that our institution has been recognized and admired by the elite and civilized societies nationally and internationally. Geetanjali is a catalyst partner of academic and socio-economic culture.",

      messagePara3:
        "We believe constant updating with and about the overwhelming world is true learning. Geetanjali awakes and urges the students share and internalize principles of adopting confidence, discipline, responsibility, loyality and result orientation. Geetanjali offers several OJTs (On the Job Trainings), internship, workshops, field visits, college tours, trekking, hiking, overnight stay-out and so on for the refreshment and wholesome development of the students and the faculties.",

      messagePara4:
        "Our BBS programme offers fundamentals of theoretical knowledge which directly links in applied arena of business. BBS full course incorporates the bond between business and management issues. Our BBS pass-outs are furnished by the opportunities to choose any kind of jobs and business across the nation and beyond. Further more , they are eligible to choose any management specialization courses to acquire further degrees of functional expertise.",

      messagePara5:
        "The college has established Entrepreneurship Incubation Centre to transform the business ideas of youths into reality. We are always working on ‘ How we can support our students for the best outcomes’. Whatever field our students choose, we are here to offer them the guidance , instructions and support they need. In order to facilitate our students and to realize their fullest potentiality over the period of four years, the college has adequate support system such as friendly classrooms, library, seminar hall and value added programme throughout the academic years. So come and meet us or get-in-touch to find out how we can support you to begin your new academic and professional journey. At the end I would like to remember the famous quotation regarding education by different scholars which may impress and inspire the young learners to make gigantic effort",
    },
  ]);

  return (
    <section>
      <div className="container my-5">
        <div>
          {message.map((messages) => (
            <>
              <div className="row principal-message mb-4">
                <div className="col">
                  <h4 className="fw-bold">{messages.title}</h4>
                  <p className="m-0 text-muted">{messages.subtitle}</p>
                  <p className="text-muted">{messages.greeting}</p>
                </div>
              </div>

              <div className="content">
                <p className="text-justify">{messages.messagePara1}</p>
                <p className="text-justify">{messages.messagePara2}</p>
                <p className="text-justify">{messages.messagePara3}</p>
                <p className="text-justify">{messages.messagePara4}</p>
                <p className="fw-bold">
                  BBS programme prepares and enables the students to:
                </p>
                <ol>
                  <li>
                    Strenghen foundations of business such as language
                    development, economic analysis, legal business orders,
                    applications of management principles and concepts.
                  </li>
                  <li>
                    Get opportunity to concentrate in one specialized sector
                    such as Accounting, Marketing, Finance , Human Resource
                    Management with some functional expertise gained throughout
                    the course.
                  </li>
                  <li>
                    Choose or even change the special sector of management like
                    Tourism, Transport, Finance, Hotels and other businesses.
                  </li>
                  <li>
                    Compete with any other programmes of other universities in
                    and abroad.
                  </li>
                </ol>
                <p className="text-justify">{messages.messagePara5}</p>
                <div className="alert alert-info message-info-container">
                  <em>
                    "When the student is ready, the teacher will appear." -
                    Gautam Buddha
                  </em>
                  <br />
                  <em>
                    "Education is not the preparation for lige, education is
                    life itslef." - John Dewey
                  </em>
                </div>
                <p>
                  I look forward to inviting you to extend the relation with
                  this elite community. Thank you!
                </p>

                <p className="fw-bold">Bachelors Coordinator</p>
                <p className="fw-bold">Mr. Basudev Adhikari</p>
              </div>
            </>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SchoolMessage;
