import React from "react";
import pdf from "../Images/Calendar/CALENDER-2081.pdf";

const Calender = () => {
  return (
    <div className="my-5 pdf-view">
      <h4 className="text-center">GESS Calender 2081</h4>
      <iframe src={pdf} frameborder="0"></iframe>
    </div>
  );
};

export default Calender;
